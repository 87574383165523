import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DomainService } from '../services/domain.service';



@Injectable({ providedIn: 'root' })
export class FeatureGuard {
    constructor(
        private router: Router,
        private domain: DomainService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.domain.isFutureEnabled(route.data.featureName)) {
            return true;
        }

        // not enabled for this domain (customer)
        this.router.navigate(['unauthorized'], { queryParams: { reason: 'FeatureNotEnabled' } });
        return false;
    }
}
