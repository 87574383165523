import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { tap, share, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        private userService: UserService) {

    }

    login(username: string, password: string) {
        return this.http.post<any>(environment.apiUrl + 'user/login', { username, password })
            .pipe(
                tap(res => this.setSession(res)),
                share()
            );
    }

    private setSession(authResult: any) {
        if (authResult != null) {
            localStorage.setItem('auth_token', authResult.access_token);
            localStorage.setItem('auth_expires', JSON.stringify(authResult.expires.valueOf()));
            this.setUsername(authResult.userId.valueOf());
            this.setCompany(authResult.companyId.valueOf());
            this.userService.getUserProfile().subscribe((rez:any) => {
                this.setLanguage(rez.language);
            });
            this.retriveUserPermissions();
        }
    }

    logout() {
        localStorage.clear();
    }

    isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    retriveUserPermissions() {
        this.userService.getUserPermissions().subscribe((rez:string[]) => {
            localStorage.setItem('auth_permissions', JSON.stringify(rez));
        });
    }

    getExpiration() {
        const expiration = localStorage.getItem('auth_expires');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    setUsername(username: string) {
        localStorage.setItem('auth_username', username);
    }

    getUsername() {
        return localStorage.getItem('auth_username');
    }

    setCompany(comapny: string) {
        localStorage.setItem('auth_company', comapny);
    }
    getCompany() {
        return localStorage.getItem('auth_company');
    }

    getLanguage() {
        const language = localStorage.getItem('auth_language');
        return language || 'en-gb';
    }

    setLanguage(lan: string) {
        if(lan) {
            localStorage.setItem('auth_language', lan);
            this.translate.use(lan.split('-')[0]);
        }
    }

    hasPermission(permission: string) {
        const userPermissions: Array<string> = JSON.parse(localStorage.getItem('auth_permissions')) || [];
        const match: boolean = userPermissions.some((p:string) => p == permission);
        if (match) {
            return true;
        }
        return false;
    }

    hasPermissions(permissions: string[]) {
        const userPermissions: Array<string> = JSON.parse(localStorage.getItem('auth_permissions')) || [];
        const match: boolean = userPermissions.some((p:string) => permissions.includes(p));
        if (match) {
            return true;
        }
        return false;
    }

    getAllPermissions() {
        return JSON.parse(localStorage.getItem('auth_permissions')) || [];
    }
}