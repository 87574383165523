<div *ngIf="id != 'null'" class="mt-3" style="height: calc(100%)">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)" style="height: calc(100%);">
        <kendo-tabstrip-tab [title]="'Site Overview'" [selected]="true">
            <ng-template kendoTabContent>
                <app-site-details></app-site-details>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Operating Schedule / Hours'">
            <ng-template kendoTabContent>
                <app-site-schedule></app-site-schedule>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Signals'">
            <ng-template kendoTabContent>
                <app-site-devices></app-site-devices>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Users'" *ngIf="false">
            <ng-template kendoTabContent>
                <app-site-users></app-site-users>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Notification emails'">
            <ng-template kendoTabContent>
                <app-site-notifications></app-site-notifications>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Advanced'" *ngIf="auth.hasPermission('strankeedit')">
            <ng-template kendoTabContent>
                <app-site-settings></app-site-settings>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>

<div class="row justify-content-center mt-3" *ngIf="id == 'null'">
    <div class="alert alert-danger col-3">
        Please select location.
    </div>
</div>