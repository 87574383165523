import { Component, OnInit, OnDestroy, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR, Input, ViewChild } from '@angular/core';
import { anyChanged } from '@progress/kendo-angular-common';
import { AlarmService } from 'src/app/services/alarm.service';
import { Pagination, DataTable, SetAlarm } from 'src/app/models/alarm.model';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, SelectableSettings, RowArgs, GridComponent, ExcelModule, ColumnBase, ColumnResizeArgs } from '@progress/kendo-angular-grid';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer, Subscription } from 'rxjs';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { AlarmComMessage, AlarmComService } from 'src/app/services/alarmcom.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppNotifyService } from 'src/app/services/notify.service';
import { AuthService } from 'src/app/services/auth.service';
import { GridService } from 'src/app/services/grid.service';
import { StatusIcon } from 'src/app/models/status.icon';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})
export class AlarmComponent implements OnInit, OnDestroy {
  @Input() showOnMapIcon: boolean = false;
  @ViewChild(GridComponent) grid: GridComponent;
  //@ViewChild('colAlarmType') colAlarmType: ColumnBase;
  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };
  sort: SortDescriptor[] = [
    {
      field: 'DateTime',
      dir: 'desc'
    }
  ];
  isRetrivingData: boolean = false;
  syncTimer: Observable<number> = timer(0, 2000);
  subscription: Subscription;
  alarmTypes: number;
  accountNr: string;
  fromDate: Date;
  toDate: Date;
  gridName:string = 'liveAlarm';

  constructor(
    private alarmService: AlarmService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    public girdService: GridService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthService) {
    // Two methods because this component can be nested!
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.alarmTypes = routeParams.alarmTypes || this.alarmTypes;
    });
    this.activatedRoute.params.subscribe(routeParams => {
      this.alarmTypes = routeParams.alarmTypes || this.alarmTypes;
    });
  }

  alarmComSub: Subscription;

  ngOnInit(): void {
    // Setup Grid name for use in Column sizing
    if(this.router.url.indexOf('alarm-map') > 0)
      this.gridName = 'liveAlarmMap';
    else if(this.router.url.indexOf('alarm-states') > 0)
      this.gridName = 'liveAlarmStates';
    else
      this.gridName = 'liveAlarm';
    
      // Events
    this.alarmComSub = this.alarmCom.get().subscribe((rez: AlarmComMessage) => {
      if (rez.message == 'onMarkerClick') {
        if (rez.data != this.accountNr) {
          this.accountNr = rez.data;
          this.state = {
            take: 100,
            skip: 0
          };
          this.getData();
          this.notify.success(`Alarm table is filtered by Account No. ${this.accountNr}`);
        }
      }
    });

    this.getData();

    this.subscription = this.syncTimer.subscribe((seconds) => {
      if (!this.isRetrivingData) {
        this.getData();
      }
    });
  }

  ngOnDestroy(): void {
    this.alarmComSub.unsubscribe();
    this.subscription.unsubscribe();
  }

  getData() {
    let req: any = {
      pageIndex: this.state.skip / this.state.take,
      pageSize: this.state.take,
      orderBy: this.sort.length > 0 ? this.sort[0].field : null,
      orderDirection: this.sort?.length > 0 ? this.sort[0].dir : 'desc',
      alarmTypes: this.alarmTypes,
      accountNr: this.accountNr,
      fromDate: this.fromDate,
      toDate: this.toDate
    };
    this.isRetrivingData = true;
    this.alarmService.getAlarms(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
      //this.grid.autoFitColumns();//[this.colAlarmType]);
      this.isRetrivingData = false;
    }, e => {
      this.isRetrivingData = false;
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData();
  }

  public archiveAlarm(eventId: string): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Confirm purge',
      content: 'Are you sure you want do purge this alarm? ',
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ]
    });
    dialog.result.subscribe((result: any) => {
      if (result?.value || false) {
        this.alarmService.clearAlarm({ eventId: eventId }).subscribe((rez: any) => {
          this.notify.success('Event is purged.');
          this.getData();
        }, (e: any) => {
          this.notify.error(e.error);
        });
      }
    });
  }

  public showStoreOnMap(accountNr: string): void {
    this.accountNr = accountNr;
    this.state = {
      take: 100,
      skip: 0
    };
    this.alarmCom.push(new AlarmComMessage('showOnMap', accountNr));
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  getIcon(statusId:number) {
    return new StatusIcon(statusId);
  }
}

class ColumnOption {
  column: string;
  width: string;
}
