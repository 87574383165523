<div class="container" style="height:100%">
    <div class="row justify-content-center align-items-center" style="height:100%;">
        <div class="col-sm-5">
            <div class="border border-secondary-subtle rounded p-4 shadow-lg">
                <div class="mb-3 text-center">
                    <div class="display-6">Password reset</div>
                    <div class="lead">Can't find your password?</div>
                  </div>
                <div *ngIf="token">
                    <div class="alert" [ngClass]="isTokenValid ? 'alert-success' : 'alert-danger'">
                        <span *ngIf="isTokenValid">Token is valid, please enter new password.</span>
                        <span *ngIf="!isTokenValid"><b>Token is NOT valid</b><br>Please verify URL or copy and paste URL
                            from recived email.</span>
                    </div>
                    <form *ngIf="isTokenValid" [formGroup]="newPasswordForm" (ngSubmit)="setNewPassword()">
                        <div class="mb-2">
                            <label class="mb-2">New password:</label>
                            <input type="password" formControlName="password" class="form-control"
                                [ngClass]="{'is-invalid': f.password.invalid && f.password.dirty}">
                                <div *ngIf="f.password.invalid && f.password.dirty" class="text-danger">Min length is 8!</div>
                        </div>
                        <div class="mb-3">
                            <label class="mb-2">Repeat password:</label>
                            <input type="password" formControlName="passwordRepeat" class="form-control"
                                [ngClass]="{'is-invalid': f.passwordRepeat.invalid && f.passwordRepeat.dirty}">
                            <div *ngIf="f.passwordRepeat.errors?.mustMatch" class="text-danger">Passwords must match!</div>
                        </div>
                        <div class="row">
                            <div class="col-6"></div>
                            <div class="col-6 text-end"><button type="submit" class="btn btn-primary"
                                    [disabled]="!newPasswordForm.valid">Set new password</button></div>
                        </div>
                    </form>
                </div>
    
                <div *ngIf="!token">
                    <form *ngIf="!resetRequestCompleted" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
                        <div class="mb-4">
                            <input type="text" formControlName="username" class="form-control" placeholder="Enter your username here"
                                [ngClass]="{'is-invalid': rf.username.invalid && rf.username.dirty}">
                        </div>
                        <div class="row">
                            <div class="col-6"><a [routerLink]="[ '/login' ]" class="btn btn-link-dark link-underline link-underline-opacity-0">Return to
                                    login</a></div>
                            <div class="col-6 text-end"><button type="submit" class="btn btn-primary"
                                    [disabled]="!resetPasswordForm.valid">Send reset request</button></div>
                        </div>
                    </form>
    
                    <div *ngIf="resetRequestCompleted">
                        <h5 class="">The link to reset your password was sent to your email.</h5>
                        <p>We've sent you an email to the provided user email address with instructions on how to reset your
                            password.<br>If you don't receive an email from us within a few minutes, please try the process
                            again or check spam folder.</p>
                            <p class="text-end"><a [routerLink]="[ '/login' ]" class="btn btn-primary link-underline link-underline-opacity-0">Return to
                                login</a></p>
                    </div>
                </div>
            </div>
            <div class="mt-5 text-center">
                <img src="assets/images/logos/ControlfreqWebAlarm.png" style="max-height: 30px;">
              </div>
        </div>
    </div>
</div>

<div kendoDialogContainer></div>