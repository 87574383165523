import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LocationService } from 'src/app/services/location.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.scss']
})
export class SiteDetailsComponent implements OnInit {

  constructor(
    private locationService: LocationService,
    public auth: AuthService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      this.getData();
    });
  }

  @ViewChild('typedAddress') typedAddress: any;
  @ViewChild(GoogleMap) map: GoogleMap;
  mapZoom: number = 8;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    disableDefaultUI: true
  }
  location: any = {
    position: { lat: 0, lng: 0 },
    draggable: true
  }
  geocoder: google.maps.Geocoder = new google.maps.Geocoder();

  id: number;
  customerId: string;

  form = this.formBuilder.group({
    accountNr: [{ value: '', disabled: true }, [Validators.required,  Validators.pattern("^[0-9A-F]{4}$")]],
    imei: ['', [Validators.pattern("^[a-zA-Z0-9]*$"), Validators.maxLength(50)]],
    active: [''],
    storeName: ['', [Validators.required, Validators.maxLength(250)]],
    storeAddress: ['', Validators.maxLength(250)],
    storePhone: ['', Validators.maxLength(50)],
    storeLat: [''],
    storeLng: [''],
    httpRunner: ['']
  });
  get f() { return this.form.controls; }

  adresses: any[] = [];

  ngOnInit(): void {

  }

  geocodeAddress(e: any) {
    //console.log(this.typedAddress._text);
    this.geocoder.geocode({ address: this.typedAddress._text }, (rez) => {
      this.adresses = rez;
    });
  }

  valueNormalizer = (text: Observable<string>) => text.pipe(map((content: string) => {
    this.adresses = [];
    return {
      place_id: 'custom',
      formatted_address: content
    };
  }));

  selectedAddressChanged(e: any) {
    let lat: number = this.form.controls.storeAddress.value.geometry.location.lat();
    let lng: number = this.form.controls.storeAddress.value.geometry.location.lng();
    this.form.controls['storeLat'].setValue(lat);
    this.form.controls['storeLng'].setValue(lng);
    this.location.position = { lat: lat, lng: lng };
    this.map.panTo(this.location.position);
  }

  getData(): void {
    if (this.id) {
      this.locationService.getLocationDetails({ accountNr: this.id }).subscribe((rez: any) => {
        this.customerId = rez.customerId;
        this.form.controls['accountNr'].setValue(rez.accountNr);
        this.form.controls['imei'].setValue(rez.imei);
        this.form.controls['active'].setValue(rez.active);
        this.form.controls['httpRunner'].setValue(rez.httpRunner);
        this.form.controls['storeName'].setValue(rez.storeName);
        this.form.controls['storeAddress'].setValue({ formatted_address: rez.storeAddress, place_id: 'myId' });
        this.form.controls['storePhone'].setValue(rez.storePhone);
        if (rez.lat && rez.lng) {
          this.form.controls['storeLat'].setValue(rez.lat);
          this.form.controls['storeLng'].setValue(rez.lng);
          this.location.position = { lat: rez.lat, lng: rez.lng };
          this.map.panTo(this.location.position);
        } else {
          this.form.controls['storeLat'].setValue('');
          this.form.controls['storeLng'].setValue('');
          this.map.panTo({ lat: 0, lng: 0 });
          this.map.zoom = 2;
        }
      });
    }
  }

  onSubmit(): void {
    let values = this.form.value;
    values.storeAddress = values.storeAddress.formatted_address;
    values.accountNr = this.id;
    this.locationService.saveLocationDetails(values).subscribe((rez: any) => {
      this.notify.success('Site saved!');
    }, (e: any) => {
      this.notify.error(e.error || e.message);
    });
  }

  locationDragEnd(marker: any): void {
    this.location.position.lat = marker.getPosition().lat();
    this.location.position.lng = marker.getPosition().lng();

    this.form.controls['storeLat'].setValue(this.location.position.lat);
    this.form.controls['storeLng'].setValue(this.location.position.lng);
  }

}
