import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State } from '@progress/kendo-data-query';
import { Pagination, DataTable } from 'src/app/models/alarm.model';
import { GetLocationSenzorsReq, LocationService } from 'src/app/services/location.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { GridService } from 'src/app/services/grid.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-site-devices',
  templateUrl: './site-devices.component.html',
  styleUrls: ['./site-devices.component.scss']
})
export class SiteDevicesComponent implements OnInit {

  constructor(
    private locationService: LocationService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    public gridService: GridService,
    public auth: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      this.getData();
    });
  }

  id: string;
  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };

  addWindowOpend:boolean = false;
  addFormError: string = null;
  form = this.formBuilder.group({
    zoneNumber: ['', [Validators.required, Validators.pattern("^[0-9][0-9][0-9]$")]],
    zoneName: ['', [Validators.required]],
  });
  get f() { return this.form.controls; }

  ngOnInit(): void {
  }

  getData() {
    let req  = new GetLocationSenzorsReq();
    req.accountNr = this.id;
    req.pageIndex = this.state.skip / this.state.take;
    req.pageSize = this.state.take;
    req.orderBy = this.state.sort?.length > 0 ? this.state.sort[0].field : null;
    req.orderDirection = this.state.sort?.length > 0 ? this.state.sort[0].dir : null;
    req.search = null;

    this.locationService.getLocationSenzors(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
    });
  }

  // Grid state changed
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  public delete(item: any): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Confirm delete',
      content: 'Are you sure you want to delete this item? ',
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ]
    });
    dialog.result.subscribe((result: any) => {
      if (result?.value || false) {
        this.locationService.deleteLocationSensor({ zoneNumber: item.id, accountNr: this.id }).subscribe((rez: any) => {
          this.notify.success('Notification email is removed.');
          this.getData();
        }, (e: any) => {
          this.notify.error(e.error);
        });
      }
    });
  }

  public add() {
    // reset form
    this.form.reset();
    this.addFormError = null;
    this.addWindowOpend = true;
  }

  onAddNewClose() {
    this.addWindowOpend = false;
  }

  public onSubmit() {
    let data = {
      accountNr: this.id,
      zoneNumber: this.form.value.zoneNumber,
      zoneName: this.form.value.zoneName
    };
    this.locationService.saveLocationSensor(data).subscribe((rez:any) => {
      this.addWindowOpend = false;
      this.notify.success('Signal added.');
      this.getData();
      this.addFormError = null;
    }, (e:any) => {
      this.addFormError = e.error;      
    });
  }
  
}
