import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State } from '@progress/kendo-data-query';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Pagination, DataTable } from 'src/app/models/alarm.model';
import { AlarmService } from 'src/app/services/alarm.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';
import { GridService } from 'src/app/services/grid.service';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy  {

  constructor(
    private locationService: LocationService,
    private notify: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    public girdService: GridService) { }
  
  locationStoragePrefix: string = 'locations_';
  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };
  selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'single',
    drag: false
  };
  search: string = '';
  company: any = null;
  customers: { text: string, value: string }[];
  addNewSiteVisible: boolean = false;

  public searchModelChanged: Subject<string> = new Subject<string>();
  public searchModelChangeSubscription: Subscription;

  ngOnInit(): void {
    // Get search value
    this.search = localStorage.getItem(`${this.locationStoragePrefix}input_search`) || '';
    var c = localStorage.getItem(`${this.locationStoragePrefix}input_company`);
    if(c)
      this.company = JSON.parse(c)

    this.locationService.getCustomers().subscribe((rez: any) => {
      this.customers = rez;
    });

    this.searchModelChangeSubscription = this.searchModelChanged
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe(newSearch => {
      this.search = newSearch;
      this.getData(true);
    });
    this.addNewSiteVisible = this.router.url.indexOf('sites-management') > 0;
    this.getData(true);
  }

  ngOnDestroy(): void {
    this.searchModelChangeSubscription.unsubscribe();
  }

  getData(selectFirst:boolean = false) {
    // Save search to localStorage
    localStorage.setItem(`${this.locationStoragePrefix}input_search`, this.search);
    localStorage.setItem(`${this.locationStoragePrefix}input_company`, JSON.stringify(this.company));

    let req: any = {
      pageIndex: this.state.skip / this.state.take,
      pageSize: this.state.take,
      orderBy: this.state.sort?.length > 0 ? this.state.sort[0].field : null,
      orderDirection: this.state.sort?.length > 0 ? this.state.sort[0].dir : null,
      search: this.search,
      companyId: this.company?.value
    };
    this.locationService.getLocations(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
      if(selectFirst && rez.data.length > 0) {
        let id = rez.data[0].accountNr;
        let url = this.route.snapshot.routeConfig.path.indexOf('locations') >= 0 ? `/app/locations/${id}/view` : `/app/sites-management/${id}/view`;
        this.router.navigate([url]);
      }
    }, e => {
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  selectionChange(event: SelectionEvent): void {
    let id = event.selectedRows[0]?.dataItem.accountNr || null;
    if (id) {
      let url = this.route.snapshot.routeConfig.path.indexOf('locations') >= 0 ? `/app/locations/${id}/view` : `/app/sites-management/${id}/view`;
      this.router.navigate([url]);
    }
  }

  edit(id: string): void {
    let url = this.route.snapshot.routeConfig.path.indexOf('locations') >= 0 ? `/app/locations/${id}/view` : `/app/sites-management/${id}/view`;
    this.router.navigate([url]);
  }

  addLocation(): void {
    this.router.navigate(['/app/sites-management/null/add']);
  }

}
