import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AlarmService } from 'src/app/services/alarm.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-site-manage',
  templateUrl: './site-manage.component.html',
  styleUrls: ['./site-manage.component.scss']
})
export class SiteManageComponent implements OnInit {

  constructor(
    private alarmService: AlarmService,
    private notify: NotificationService,
    public auth: AuthService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      this.getData();
    });
  }

  id: string;

  ngOnInit(): void {
    
  }

  getData():void {
    console.log(this.id);
  }

  onTabSelect(e):void {}

}
