import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, SelectableSettings, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { DataTable } from 'src/app/models/alarm.model';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AuditLogService } from 'src/app/services/auditlog.service';
import { AuthService } from 'src/app/services/auth.service';
import { GridService } from 'src/app/services/grid.service';
import { LocationService } from 'src/app/services/location.service';
import { ScreenService } from 'src/app/services/screen.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

  constructor(
    private auditLogService: AuditLogService,
    private notify: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private route: ActivatedRoute,
    private router: Router,
    public screen: ScreenService,
    private activatedRoute: ActivatedRoute,
    public gridService: GridService,
    public auth: AuthService) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      if (this.id) {
        this.getData();
      }
    });
  }

  id: string;
  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };
  sort: SortDescriptor[] = [
    {
      field: 'DateTime',
      dir: 'desc'
    }
  ];
  fromDate: Date;
  toDate: Date;
  //userName: string = '';

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    let req: any = {
      pageIndex: this.state.skip / this.state.take,
      pageSize: this.state.take,
      orderBy: this.sort?.length > 0 ? this.sort[0].field : null,
      orderDirection: this.sort?.length > 0 ? this.sort[0].dir : null,
      fromDate: this.fromDate,
      toDate: this.toDate,
      userId: this.id
    };
    this.auditLogService.getAuditLogs(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
    }, e => {
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData();
  }

  exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

}
