import { Injectable } from "@angular/core";

@Injectable()
export class ScreenService {

    constructor() { }

    public get isMobile(): boolean {
        const screenWidth = window.innerWidth;
        return screenWidth <= 768;
    }
}