import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent, ColumnResizeArgs } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pagination, SetAlarm, ArchiveIdPagination } from '../models/alarm.model';

@Injectable()
export class GridService {

    constructor() {}

    getColWidth(gridId:string, colId:number, width?:string):string {
        let grid:GridSetting = JSON.parse(localStorage.getItem(`grid_settings_${gridId}`)) || new GridSetting();
        let col:GridColumn = grid.columns?.find((s:GridColumn) => s.index == colId);
        return col?.width || width;
    }

    private setColWidth(gridId:string, colId:number, width:string):void {
        let grid:GridSetting = JSON.parse(localStorage.getItem(`grid_settings_${gridId}`)) || new GridSetting();
        let col:GridColumn = grid.columns?.find((s:GridColumn) => s.index == colId);
        if(col) {
            col.width = width;
        } else {
            let c = new GridColumn();
            c.index = colId;
            c.width = width;
            grid.columns.push(c);
        }
        localStorage.setItem(`grid_settings_${gridId}`, JSON.stringify(grid));
    }

    onColResize(gridId:string, event:ColumnResizeArgs):void {
        let obj:any = event[0];
        this.setColWidth(gridId, obj.column.leafIndex, `${obj.newWidth}px`);
    }

}

class GridSetting {
    columns:GridColumn[] = [];
    constructor(){}
}

class GridColumn {
    index:number;
    width:string;
    constructor(){}
}