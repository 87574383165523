<div *ngIf="id != 'null'" class="mt-3 h-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col"><h4>History</h4></div>
            <div class="col-lg-2 mb-1"><kendo-datetimepicker [(value)]="fromDate" class="form-control"></kendo-datetimepicker></div>
            <div class="col-lg-2 mb-1"><kendo-datetimepicker [(value)]="toDate" class="form-control"></kendo-datetimepicker></div>
            <div class="col-lg-2 mb-1"><kendo-dropdownlist [(value)]="alarmType" [data]="alarmTypesOptions" [defaultItem]="{text: 'Show all', value:''}" class="form-control" [textField]="'text'" [valueField]="'value'"></kendo-dropdownlist></div>
            <div class="col-lg-1 mb-1"><button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="getData()">Search</button></div>
            <div class="col"></div>
            <div class="col-lg-2 mb-1 align-self-end text-end">
                <button type="button" class="btn btn-primary w-100 w-lg-auto" (click)="exportToExcel(grid)" *ngIf="auth.hasPermission('viewizpisi')">Export</button>
            </div>
        </div>
    </div>

    <kendo-grid #grid="kendoGrid" [data]="gridData" resizable="true" (columnResize)="gridService.onColResize('locationHistory', $event)"
        [pageSize]="state.take" [skip]="state.skip" [pageable]="true"
        [sortable]="{ allowUnsort:true, mode: 'single' }" [sort]="sort" (sortChange)="sortChange($event)"
        [filterable]="false" (dataStateChange)="dataStateChange($event)" class="dataGird k-grid-ignore-click" style="height: calc(100% - 40px); ">
        <kendo-grid-column field="accountNr" title="Acc. nr." [width]="gridService.getColWidth('locationHistory',0,'60px')"></kendo-grid-column>
        <kendo-grid-column field="storeName" title="Site Name" [width]="gridService.getColWidth('locationHistory',1)"></kendo-grid-column>
        <kendo-grid-column field="alarmType" title="Alarm type" [width]="gridService.getColWidth('locationHistory',1,'200px')"></kendo-grid-column>
        <kendo-grid-column field="zoneName" title="Description" [width]="gridService.getColWidth('locationHistory',7,'160px')"></kendo-grid-column>
        <kendo-grid-column field="date" title="Date" [width]="gridService.getColWidth('locationHistory',2,'100px')">
            <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.dateTime| date:'mediumDate'}}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="time" title="Time (UTC)" [width]="gridService.getColWidth('locationHistory',3,'100px')">
            <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.dateTime| date:'mediumTime'}}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="HB" [width]="gridService.getColWidth('locationHistory',5,'60px')">
            <ng-template kendoGridCellTemplate let-dataItem>
                <img [src]="getIcon(dataItem.ipLinkStatus).iconPath" [title]="'Heartbeat ' + getIcon(dataItem.ipLinkStatus).text">
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="PT" [width]="gridService.getColWidth('locationHistory',4,'60px')">
            <ng-template kendoGridCellTemplate let-dataItem>
                <img [src]="getIcon(dataItem.linkStatus).iconPath" [title]="'Periodic test ' + getIcon(dataItem.linkStatus).text">
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="ARM" [width]="gridService.getColWidth('locationHistory',6,'60px')">
            <ng-template kendoGridCellTemplate let-dataItem>
                <fa-icon *ngIf="dataItem.status == 0" [icon]="'question'" title="UNKNOWN" class="text-secondary"></fa-icon>
                <fa-icon *ngIf="dataItem.status == 1" [icon]="'unlock'" title="DISARMED" class="text-success"></fa-icon>
                <fa-icon *ngIf="dataItem.status == 2" [icon]="'lock'" title="ARMED" class="text-danger"></fa-icon>
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
        </ng-template>
        <kendo-grid-excel fileName="export.xlsx">
            <kendo-excelexport-column field="accountNr" title="Account No"></kendo-excelexport-column>
            <kendo-excelexport-column field="storeName" title="Site Name"></kendo-excelexport-column>
            <kendo-excelexport-column field="alarmType" title="Alarm type"></kendo-excelexport-column>
            <kendo-excelexport-column field="dateTime" title="Event date"></kendo-excelexport-column>
            <kendo-excelexport-column field="linkStatus" title="Link status"></kendo-excelexport-column>
            <kendo-excelexport-column field="status" title="Status"></kendo-excelexport-column>
        </kendo-grid-excel>
    </kendo-grid>
</div>

<div class="row justify-content-center mt-3" *ngIf="id == 'null'">
    <div class="alert alert-danger col-3">
        Please select location.
    </div>
</div>