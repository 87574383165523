<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <h4>Users</h4>
        </div>
        <div class="col">
            <div class="row g-3 align-items-center">
                <div class="col align-self-end text-end"><button type="button" class="btn btn-primary" (click)="exportToExcel(grid)" *ngIf="auth.hasPermission('viewizpisi')">Export</button></div>
            </div>
        </div>
    </div>
</div>

<kendo-grid #grid="kendoGrid" [data]="gridData" [pageSize]="state.take" [skip]="state.skip" [sortable]="true" [pageable]="true"
    [filterable]="false" (dataStateChange)="dataStateChange($event)"
    class="dataGird k-grid-ignore-click" style="height: calc(100% - 40px)" resizable="true" (columnResize)="gridService.onColResize('siteUsers', $event)">
    <kendo-grid-column field="id" title="Id" [width]="gridService.getColWidth('siteUsers',0,'100px')"></kendo-grid-column>
    <kendo-grid-column field="userName" title="Name" [width]="gridService.getColWidth('siteUsers',1)"></kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="export.xlsx"></kendo-grid-excel>
</kendo-grid>