<div class="container-fluid mb-3">
    <div>Network-level SIM purge and re-register to reset any connectivity related downtime.</div>
    <div style="font-style: italic">To be used only after preliminary parameter checks have been conducted. Contact
        ControlFreq support.</div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-6">
            <button type="submit" class="btn btn-primary w-100 w-lg-auto" (click)="refreshSim()">Refresh SIM</button>
            <div *ngIf="taskStatus" class="mt-3">
                Status: <b>{{taskStatus.status}}</b> ({{taskStatus.created | date:'mediumTime'}})
                <div *ngIf="taskStatus.errorMessage != ''">
                    Error: {{taskStatus.errorMessage}}
                </div>
            </div>
            <div *ngIf="!taskStatus" class="mt-3">
                <fa-icon [icon]="['fas', 'info-circle']" class="me-2"></fa-icon> Progress will appear here.
            </div>
        </div>
    </div>
</div>

<!--
{ "isSuccess": true, "result": { "customerRequestBatchRequests": [ { "id": 33117423, "sim": "8931081321068698521", "status": "complete", "errorMessage": "", "created": "2024-06-21T11:32:55+01:00", "action": "sim_refresh" } ] }, "errorMessage": null, "errorCode": null }
-->