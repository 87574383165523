import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pagination, SetAlarm, ArchiveIdPagination } from '../models/alarm.model';

@Injectable()
export class AlarmService {

    constructor(private http: HttpClient) {}

    getAlarms(data: any) {
        return this.http.post(`${environment.apiUrl}alarm/getAlarms`, data);
    }

    getEvents(data: any) {
        return this.http.post(`${environment.apiUrl}alarm/getEvents`, data);
    }

    clearAlarm(data: any) {
        return this.http.post(`${environment.apiUrl}alarm/clearAlarm`, data);
    }

}