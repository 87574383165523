export class StatusIcon {
    id: number;
    private basePath = '/assets/images/marker/';

    constructor(id: number) {
        this.id = id;
    }

    get value(): number {
        return this.id;
    }

    get iconPath(): string {
        switch (this.id) {
            case -1: return `${this.basePath}gray.gif`;           //gray
            case 0: return `${this.basePath}red.gif`;             //red
            case 1: return `${this.basePath}green.gif`;           //green
            case 2: return `${this.basePath}orange.gif`;          //orange
            default: return `${this.basePath}gray.gif`;           //gray
        }
    }

    get text(): string {
        switch (this.id) {
            case -1: return `Unknown`; //gray
            case 0: return `Offline`; //red
            case 1: return `Ok`; //green
            case 2: return `Missed`; //orange
            default: return `Unknown`; //gray
        }
    }
} 