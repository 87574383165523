import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State } from '@progress/kendo-data-query';
import { Pagination, DataTable } from 'src/app/models/alarm.model';
import { LocationService } from 'src/app/services/location.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { GridService } from 'src/app/services/grid.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-site-users',
  templateUrl: './site-users.component.html',
  styleUrls: ['./site-users.component.scss']
})
export class SiteUsersComponent implements OnInit {

  constructor(
    private locationService: LocationService,
    private notify: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    public gridService: GridService,
    public auth: AuthService,
    private router: Router) {
      this.activatedRoute.parent.params.subscribe(routeParams => {
        this.id = routeParams.id;
        this.getData();
      });
  }

  id: number;
  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };

  ngOnInit(): void {
  }

  getData() {
    let req  = {
      accountNr: this.id,
      pageIndex: this.state.skip / this.state.take,
      pageSize: this.state.take,
      orderBy: this.state.sort?.length > 0 ? this.state.sort[0].field : null,
      orderDirection: this.state.sort?.length > 0 ? this.state.sort[0].dir : null,
      search: null
    }

    this.locationService.getLocationUsers(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
    });
  }

  // Grid state changed
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

}
