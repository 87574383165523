import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pagination, SetAlarm, ArchiveIdPagination } from '../models/alarm.model';

@Injectable()
export class LocationService {

    constructor(private http: HttpClient) {}

    getLocations(data: any) {
        return this.http.post(`${environment.apiUrl}location/getLocations`, data);
    }

    getLocationDetails(data: any) {
        return this.http.post(`${environment.apiUrl}location/getLocationDetails`, data);
    }

    saveLocationDetails(data: any) {
        return this.http.post(`${environment.apiUrl}location/saveLocationDetails`, data);
    }

    getLocationSchedule(data: any) {
        return this.http.post(`${environment.apiUrl}location/getLocationSchedule`, data);
    }

    saveLocationSchedule(data: any) {
        return this.http.post(`${environment.apiUrl}location/saveLocationSchedule`, data);
    }

    getLocationSenzors(data: GetLocationSenzorsReq) {
        return this.http.post(`${environment.apiUrl}location/getLocationSenzors`, data);
    }

    getLocationUsers(data: any) {
        return this.http.post(`${environment.apiUrl}location/getLocationUsers`, data);
    }

    getCustomers() {
        return this.http.get(`${environment.apiUrl}location/GetCompanies`);
    }

    getLocationNotificationEmails(data: any) {
        return this.http.post(`${environment.apiUrl}location/getLocationNotificationEmails`, data);
    }

    deleteLocationNotificationEmail(data: any) {
        return this.http.post(`${environment.apiUrl}location/DeleteLocationNotificationEmail`, data);
    }

    saveLocationNotificationEmail(data: any) {
        return this.http.post(`${environment.apiUrl}location/SaveLocationNotificationEmail`, data);
    }

    deleteLocationSensor(data: any) {
        return this.http.post(`${environment.apiUrl}location/DeleteLocationSensor`, data);
    }

    saveLocationSensor(data: any) {
        return this.http.post(`${environment.apiUrl}location/SaveLocationSensor`, data);
    }

    resetSIM(accountNr: String) {
        return this.http.get(`${environment.apiUrl}location/resetSIM?accountNr=${accountNr}`);
    }
    
    
    getResetSIMStatus(taskId: number) {
        return this.http.get(`${environment.apiUrl}location/getResetSIMStatus?taskId=${taskId}`);
    }
}

export class GetLocationSenzorsReq {
    accountNr: string;
    pageIndex: number;
    pageSize: number;
    search: string;
    orderBy: string;
    orderDirection: string;
}