import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { MyAppComponent } from './components/app/app.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { PublicOnlyGuard } from './guards/public.guard';
import { AlarmComponent } from './components/alarm/alarm.component';
import { AlarmWithMapComponent } from './components/alarmWithMap/alarmWithMap';
import { LocationsComponent } from './components/locations/locations.component';
import { LocationEventsComponent } from './components/location-events/location-events.component';
import { SiteManageComponent } from './components/site-manage/site-manage.component';
import { AuditLogComponent } from './components/app-user/audit-log/audit-log.component';
import { SiteDetailsComponent } from './components/site-manage/site-details/site-details.component';
import { AddSiteComponent } from './components/add-site/add-site.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { RolesGuard } from './guards/roles.guard';
import { AppUsersComponent } from './components/app-users/app-users.component';
import { UserEditComponent } from './components/app-user/user-edit/user-edit.component';
import { AppUserComponent } from './components/app-user/app-user.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { LocationsCenectivityComponent } from './components/locations-cenectivity/locations-cenectivity-component';
import { FeatureGuard } from './guards/feature.guard';
import { DomainService } from './services/domain.service';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: new DomainService().getDomainHomeRoute()//'app/alarm'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'passwordreset',
    component: PasswordResetComponent,
    canActivate: [PublicOnlyGuard]
  },
  {
    path: 'passwordreset/:token',
    component: PasswordResetComponent,
    canActivate: [PublicOnlyGuard]
  },
  {
    path: 'login/:language',
    component: LoginComponent
  },
  {
    path: 'app', component: MyAppComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'alarm', redirectTo: 'alarm/0'
      },
      {
        path: 'alarm/:alarmTypes', canActivate: [FeatureGuard], component: AlarmComponent,
        data: { featureName: 'alarm' }
      },
      {
        path: 'alarm-map', redirectTo: 'alarm-map/0'
      },
      {
        path: 'alarm-map/:alarmTypes', canActivate: [FeatureGuard], component: AlarmWithMapComponent,
        data: { featureName: 'alarm-map' }
      },
      {
        path: 'alarm-conectivity', canActivate: [FeatureGuard], component: LocationsCenectivityComponent,
        data: { featureName: 'alarm-conectivity' }
      },
      {
        path: 'alarm-states', redirectTo: 'alarm-states/1',
      },
      {
        path: 'alarm-states/:alarmTypes', canActivate: [FeatureGuard], component: AlarmComponent,
        data: { featureName: 'alarm-states' }
      },
      {
        path: 'locations', redirectTo: 'locations/null/view'
      },
      {
        path: 'locations/:id', canActivate: [FeatureGuard], component: LocationsComponent,
        data: { featureName: 'locations' },
        children: [
          {
            path: 'view', component: LocationEventsComponent
          }
        ]
      },
      {
        path: 'locationHistory/:id', canActivate: [FeatureGuard], component: LocationEventsComponent,
        data: { featureName: 'locationHistory' }
      },
      {
        path: 'sites-management', redirectTo: 'sites-management/null/view'
      },
      {
        path: 'sites-management/:id', component: LocationsComponent, canActivate: [RolesGuard],
        data: { roles: ['strankeedit','master'] },
        children: [
          {
            path: 'view', component: SiteManageComponent
          },
          {
            path: 'add', component: AddSiteComponent
          }
        ]
      },
      {
        path: 'app-users', redirectTo: 'app-users/null'
      },
      {
        path: 'app-users/:id', component: AppUsersComponent, canActivate: [RolesGuard],
        data: { roles: ['strankeedit','master'] },
        children: [
          {
            path: 'details', component: AppUserComponent
          }
        ]
      }
    ]
  },
  { path: '404', component: NotFoundComponent },
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
