import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DomainService } from 'src/app/services/domain.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private renderer: Renderer2,
    public domain: DomainService,
    private dialogService: DialogService,
    private userService: UserService
  ) {
    
  }

  token: string;
  isTokenValid: boolean = false;
  resetRequestCompleted: boolean = false;

  newPasswordForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    passwordRepeat: ['', [Validators.required]]
  }, { validator: this.passwordMatchValidator });
  get f() { return this.newPasswordForm.controls; }

  resetPasswordForm = this.fb.group({
    username: ['', [Validators.required]]
  });
  get rf() { return this.resetPasswordForm.controls; }

  ngOnInit(): void {
    this.token = this.route.snapshot.params.token;
    if (this.token) {
      this.validateToken();
    }
  }

  ngOnDestroy() {
    
  }

  validateToken(): void {
    this.userService.validatePasswordResetToken({ token: this.token }).subscribe(rez => {
      this.isTokenValid = true;
    }, e => {
      this.isTokenValid = false;
    });
  }

  setNewPassword(): void {
    this.userService.setNewPasswordReset({ token: this.token, password: this.newPasswordForm.controls['password'].value }).subscribe(rez => {
      const dialog: DialogRef = this.dialogService.open({
        title: 'Password reset',
        content: 'Password is saved, please continue to login.',
        actions: [
          { text: 'Login now', value: true, primary: true }
        ]
      });
      dialog.result.subscribe((result: any) => {
        this.router.navigateByUrl('/login');
      });
    }, e => {
      const dialog: DialogRef = this.dialogService.open({
        title: 'Password reset',
        content: e.message,
        actions: [
          { text: 'Ok', value: true, primary: true }
        ]
      });
    });
  }

  resetPassword(): void {
    this.userService.startPasswordReset(this.resetPasswordForm.value).subscribe(rez => {
      this.resetRequestCompleted = true;
    });
  }


  passwordMatchValidator(frm: UntypedFormGroup) {
    if (frm.controls['password'].value != frm.controls['passwordRepeat'].value) {
      frm.controls['passwordRepeat'].setErrors({ mustMatch: true });
    } else {
      frm.controls['passwordRepeat'].setErrors(null);
    }

  }

}
