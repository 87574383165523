import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Observable, timer, Subscription } from 'rxjs';
import { AlarmComMessage, AlarmComService } from 'src/app/services/alarmcom.service';
import { LocationService } from 'src/app/services/location.service';
import { MapsService } from 'src/app/services/maps.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';
import { StatusIcon } from 'src/app/models/status.icon';
import { LocationData } from 'src/app/models/location.data.model';
import { AlarmIcon } from 'src/app/models/alarm.icon';
import { ScreenService } from 'src/app/services/screen.service';

// All about Google Maps Component
// https://timdeschryver.dev/blog/google-maps-as-an-angular-component
// https://github.com/angular/components/tree/master/src/google-maps#readme
// https://developers.google.com/maps/documentation/javascript/examples/marker-modern
// https://developers.google.com/maps/documentation/javascript/examples/advanced-markers-graphics

@Component({
  selector: 'app-alarmWithMap',
  templateUrl: './alarmWithMap.html',
  styleUrls: ['./alarmWithMap.scss']
})
export class AlarmWithMapComponent implements OnInit, OnDestroy {

  isRetrivingData: boolean = false;
  syncTimer: Observable<number> = timer(0, 3000);
  syncSubscription: Subscription;
  alarmComSubscription: Subscription;
  activeAccountNr: string;

  //orientation: "horizontal" | "vertical" = this.screen.isMobile ? "horizontal" : "vertical";

  // Map
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild(MapInfoWindow) mapInfoWindow: MapInfoWindow;
  mapZoom: number = 6;
  mapCenter: google.maps.LatLngLiteral = { lat: 51.509865, lng: 0.118092 };
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 1,
    disableDefaultUI: true
  }
  mapMarkers: any[] = [];
  mapInfoWindowContent: any = {};

  customers: { text: string, value: string }[];

  data:LocationData[] = [];

  filters: any = {
    search: '',
    companyId: null,
    inAlarm: null,
    statusId: null
  }

  stateFilters: any[] = [
    new AlarmIcon(0),
    new AlarmIcon(1)
  ]

  constructor(
    private mapsService: MapsService,
    public auth: AuthService,
    private locationService: LocationService,
    private notify: AppNotifyService,
    private alarmCom: AlarmComService,
    public screen: ScreenService) {
    let _f = localStorage.getItem('AlarmWithMapComponentFilters');
    if (_f) {
      this.filters = JSON.parse(_f);
    }
  }

  ngOnInit(): void {
    // Subscribe to events from AlarmTable
    this.alarmComSubscription = this.alarmCom.get().subscribe((rez: AlarmComMessage) => {
      if (rez.message == 'showOnMap') {
        if (rez.data != this.activeAccountNr) {
          this.activeAccountNr = rez.data;
          let marker: any = this.mapMarkers.find((m: any) => m.info.accountNr == this.activeAccountNr);
          if (marker) {
            this.markerClick(marker, marker.info);
            this.notify.success(`Account Nr. ${this.activeAccountNr} is now selected on map.`);
            if (!this.isRetrivingData) {
              this.getData();
            }
          } else {
            this.notify.error(`Location not found on map.`);
          }
        }
      }
    });
    // Subscribe to update gui timer
    this.syncSubscription = this.syncTimer.subscribe((seconds) => {
      if (!this.isRetrivingData) {
        this.getData();
      }
    });

    this.locationService.getCustomers().subscribe((rez: any) => {
      this.customers = rez;
      this.getData();
    });
  }

  ngOnDestroy(): void {
    // Dispose subscriptions
    this.alarmComSubscription.unsubscribe();
    this.syncSubscription.unsubscribe();
  }

  onFilter() {
    localStorage.setItem('AlarmWithMapComponentFilters', JSON.stringify(this.filters));
    this.firstLoad = true;
    this.getData();
  }

  getData() {
    // Fix filters to match API
    if (this.filters.statusId == null) {
      this.filters.inAlarm = null;
    }
    else {
      this.filters.inAlarm = this.filters.statusId;
    }

    this.isRetrivingData = true;
    this.mapsService.getStoreLocationsWithProperties(this.filters).subscribe((rez: LocationData[]) =>  {
      this.data = rez;
      this.proccessLocations(rez);
    });
  }

  private firstLoad: boolean = true;
  private proccessLocations(locations: LocationData[]): void {
    let bounds = new google.maps.LatLngBounds();
    locations.forEach((l: LocationData) => {
      let m = this.mapMarkers.find((m: any) => m.info.accountNr == l.accountNr);
      bounds.extend({ lat: l.latitude, lng: l.longitude });
      if (m) {
        m.position = { lat: l.latitude, lng: l.longitude };
        m.title = l.storeName;
        m.info = l;
        m.options = this.getMarkerIcon(l);
      } else {
        this.mapMarkers.push({
          position: { lat: l.latitude, lng: l.longitude },
          title: l.storeName,
          info: l,
          clickable: true,
          draggable: false,
          options: this.getMarkerIcon(l)
        });
      }
    });
    // filter out markers that are no longer returned by server
    this.mapMarkers = this.mapMarkers.filter((m: any) => locations.find((l: LocationData) => l.accountNr == m.info.accountNr));
    // end
    this.isRetrivingData = false;
    if (this.firstLoad) {
      // zoom to all markers
      this.map.fitBounds(bounds);
    }
    this.firstLoad = false;
  }

  private getMarkerIcon(data: LocationData): any {
    let icon = new AlarmIcon(data.isInAlarm);
    return { icon: icon.iconPath };
  }

  markerClick(marker: MapMarker, info?: LocationData): void {
    this.activeAccountNr = info?.accountNr || this.activeAccountNr;
    this.locationService.getLocationDetails({ accountNr: this.activeAccountNr }).subscribe((rez: any) => {
      this.mapInfoWindowContent = rez;
      this.mapInfoWindow.position = { lat: info.latitude, lng: info.longitude };
      this.mapInfoWindow.open();
    });
    if (!this.isRetrivingData) {
      this.getData();
    }
    this.alarmCom.push(new AlarmComMessage('onMarkerClick', this.activeAccountNr));
  }

  infoWindowClosed(): void {
    this.activeAccountNr = null;
    this.alarmCom.push(new AlarmComMessage('onMarkerClick', this.activeAccountNr));
  }

}