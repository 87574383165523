export class DomainCustomer {
    domain: string;
    logoUrl: string;
    footerText: string;
    disabledFutures: string[] = [];
    homePath: string = 'app/alarm';

    constructor(domain:string, logoUrl: string, footerText?: string, disabledFutures?:string[], homePath?:string) {
        this.domain = domain;
        this.logoUrl = logoUrl;
        this.disabledFutures = disabledFutures || [];
        this.footerText = footerText;
        this.homePath = homePath || 'app/alarm';
    };
}