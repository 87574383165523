<div class="container-fluid px-3 py-2 bg-light">
    <div class="row">
        <div class="col-lg-2 mb-1">
            <input type="text" [ngModel]="search"
                (ngModelChange)='searchModelChanged.next($event)' class="form-control form-control-sm"
                placeholder="Search by username"></div>
        <div class="col-lg-2 mb-1" *ngIf="auth.hasPermission('su_user')">
            <kendo-dropdownlist [data]="customers" [textField]="'text'" [valueField]="'value'"
                 [filterable]="true" [(ngModel)]="company"
                [defaultItem]="{ text: 'Select Customer', value: null }" (valueChange)="getData()">
            </kendo-dropdownlist>
        </div>
        <div class="col-lg-1 mb-1"><button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="getData()">Search</button></div>
        <div class="col"></div>
        <div class="col-lg-1 mb-1 align-self-end text-end" *ngIf="auth.hasPermission('strankeadmin')"><button
                type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="addNewUser()">Add user</button></div>
    </div>
</div>

<kendo-grid [data]="gridData" [pageSize]="state.take" [skip]="state.skip" [sortable]="true" [pageable]="true"
    resizable="true" [filterable]="false" (dataStateChange)="dataStateChange($event)"
    class="dataGird k-grid-ignore-click" [selectable]="selectableSettings" (selectionChange)="selectionChange($event)"
    (columnResize)="girdService.onColResize('locations', $event)">
    <kendo-grid-column field="username" title="User Name" [width]="girdService.getColWidth('appusers',0,'160px')">
    </kendo-grid-column>
    <kendo-grid-column field="userRole" title="User Role" [width]="girdService.getColWidth('appusers',1,'160px')">
    </kendo-grid-column>
    <kendo-grid-column field="lastActiveOn" title="Live" [width]="girdService.getColWidth('appusers',2,'160px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.isOnline">
                <fa-icon icon="circle" class="text-success" title="Last seen {{dataItem.lastActiveOn| date:'medium'}}">
                </fa-icon> Online
            </span>
            <span *ngIf="!dataItem.isOnline">
                <fa-icon icon="circle" class="text-danger" title="Last seen {{dataItem.lastActiveOn| date:'medium'}}">
                </fa-icon> Offline
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="girdService.getColWidth('appusers',3,'160px')" class="text-end">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button type="button" (click)="logUserOut(dataItem)" class="btn btn-warning btn-sm me-3" title="Sign out">
                <fa-icon icon="sign-out-alt"></fa-icon>
            </button>
            <button type="button" (click)="saveUserStatus(dataItem)" class="btn btn-primary btn-sm" title="Delete">
                <fa-icon icon="trash-alt"></fa-icon>
            </button>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>

<div kendoDialogContainer></div>
<router-outlet></router-outlet>