import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LocationService } from 'src/app/services/location.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-site-schedule',
  templateUrl: './site-schedule.component.html',
  styleUrls: ['./site-schedule.component.scss']
})
export class SiteScheduleComponent implements OnInit {

  constructor(
    private locationService: LocationService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      this.getData();
    });
  }

  id: string;
  columnWidth: string = '180px';
  days: Array<string> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  form: UntypedFormArray = new UntypedFormArray ([]);
  get f() { return this.form.controls; }

  ngOnInit(): void {
    
  }

  getData():void {
    if (this.id) {
      this.locationService.getLocationSchedule({ accountNr: this.id }).subscribe((rez: any) => {
        this.form.clear();
        for(let i=0; i<7; i++) {
          let fg = new UntypedFormGroup({
            set: new UntypedFormControl(this.buildDate(rez[i].start)),
            unset: new UntypedFormControl(this.buildDate(rez[i].stop))
          }, this.startStopValidator);
          this.form.push(fg);
        }
      });
    }
  }

  startStopValidator(formGroup: UntypedFormGroup) {
    if ((formGroup.value.set && !formGroup.value.unset) || (!formGroup.value.set && formGroup.value.unset)) {
      return { 'startAndStopRequired': true };
    } else if(formGroup.value.set > formGroup.value.unset) {
      return { 'stopIsGreaterThanStart': true };
    }
    return null;
  }

  private buildDate(time:string):Date {
    if(time) {
      return new Date('1970.01.01 ' + time);
    } else {
      return null;
    }
  }

  private getTimeFromDate(date:Date): string {
    return date ? `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}` : null;
  }

  onSubmit():void {
    const values = this.form.value;
    let data = {
      accountNr: this.id,
      Monday: { start: this.getTimeFromDate(values[0].set), stop: this.getTimeFromDate(values[0].unset) },
      Tuesday: { start: this.getTimeFromDate(values[1].set), stop: this.getTimeFromDate(values[1].unset) },
      Wednesday: { start: this.getTimeFromDate(values[2].set), stop: this.getTimeFromDate(values[2].unset) },
      Thursday: { start: this.getTimeFromDate(values[3].set), stop: this.getTimeFromDate(values[3].unset) },
      Friday: { start: this.getTimeFromDate(values[4].set), stop: this.getTimeFromDate(values[4].unset) },
      Saturday: { start: this.getTimeFromDate(values[5].set), stop: this.getTimeFromDate(values[5].unset) },
      Sunday: { start: this.getTimeFromDate(values[6].set), stop: this.getTimeFromDate(values[6].unset) }
    }
    this.locationService.saveLocationSchedule(data).subscribe((rez:any) => {
      this.notify.success('Schedule is updated.');
    }, (e:any) => {
      this.notify.error(e.error || e.message);
    });
  }

  coppyFirstDayToAll():void {
    const set = this.form.value[0].set;
    const unset = this.form.value[0].unset;

    for(var i=1; i<=6; i++) {
      this.form.controls[i].setValue({
        set: set,
        unset: unset
      });
    }
  }

  clearInput(controls):void {
    controls.set.setValue(null);
    controls.unset.setValue(null);
  }
  
}
