<div class="container" style="height:100%">
  <div class="row justify-content-center align-items-center" style="height:100%;">
    <div class="col-sm-5">
      <div class="border border-secondary-subtle rounded p-4 shadow-lg">
        <div class="mb-3 text-center">
          <div class="display-6">Sign in</div>
          <div class="lead">Sign in to get started.</div>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
          <div class="mb-2">
            <input type="text" formControlName="userName" class="form-control" placeholder="{{'component.login.emailLabel'|translate}}"
              [ngClass]="{'is-invalid': f.userName.invalid && f.userName.dirty}">
          </div>
          <div class="mb-3">
            <input type="password" formControlName="password" class="form-control" placeholder="{{'component.login.passwordLabel'|translate}}"
              [ngClass]="{'is-invalid': f.password.invalid && f.password.dirty}">
          </div>
          <div *ngIf="loginFaild" class="alert alert-danger mb-3" [innerHTML]="loginFaild">
          </div>
          <div class="row">
            <div class="col-6"><a [routerLink]="[ '/passwordreset' ]" class="btn btn-link-dark link-underline link-underline-opacity-0" >Forgot your password?</a></div>
            <div class="col-6 text-end"><button type="submit" class="btn btn-primary" [disabled]="!loginForm.valid">{{'component.login.loginBtn'|translate}}</button></div>
          </div>
        </form>
      </div>
      <div class="mt-5 text-center">
        <img src="assets/images/logos/ControlfreqWebAlarm.png" style="max-height: 30px;">
      </div>
    </div>
  </div>
</div>