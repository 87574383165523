<div class="container-fluid px-3 py-2 bg-light">
    <div class="row">
        <div class="col"></div>
        <div class="col-lg-1 mb-1 align-self-end">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="add()"
                *ngIf="auth.hasPermission('strankeedit')">Add</button>
        </div>
        <div class="col-lg-1 mb-1 align-self-end">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="exportToExcel(grid)"
                *ngIf="auth.hasPermission('viewizpisi')">Export</button>
        </div>
    </div>
</div>

<kendo-grid #grid="kendoGrid" [data]="gridData" [pageSize]="state.take" [skip]="state.skip" [sortable]="true"
    [pageable]="true" [filterable]="false" (dataStateChange)="dataStateChange($event)"
    class="dataGird k-grid-ignore-click" style="height: calc(100% - 40px);  min-height: 400px;" resizable="true"
    (columnResize)="gridService.onColResize('siteDetectors', $event)">
    <kendo-grid-column field="id" title="Id" [width]="gridService.getColWidth('siteDetectors',0,'100px')">
    </kendo-grid-column>
    <kendo-grid-column field="senzorName" title="Name" [width]="gridService.getColWidth('siteDetectors',1)">
    </kendo-grid-column>
    <kendo-grid-column [width]="gridService.getColWidth('siteDetectors',2,'100px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-center">
                <fa-icon *ngIf="auth.hasPermission('master')" icon="trash-alt" class="text-danger hover"
                    (click)="delete(dataItem)"></fa-icon>
            </div>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="export.xlsx"></kendo-grid-excel>
</kendo-grid>

<div kendoDialogContainer></div>

<kendo-window title="Add signal" *ngIf="addWindowOpend" width="350px"
    (close)="onAddNewClose()">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="container-fluid">
            <div class="mb-3">
                <label class="form-label">Signal number:</label>
                <input type="text" class="form-control" placeholder="Ex. 003" formControlName="zoneNumber" [ngClass]="{'is-invalid': f.zoneNumber.invalid && f.zoneNumber.dirty}">
                <small *ngIf="f.zoneNumber.invalid && f.zoneNumber.dirty" class="text-danger">Must be number and exactly 3 characters length. <br>Example '003'.</small>
            </div>
            <div class="mb-3">
                <label class="form-label">Signal name:</label>
                <input type="text" class="form-control" formControlName="zoneName" [ngClass]="{'is-invalid': f.zoneName.invalid && f.zoneName.dirty}">
            </div>
            <div class="alert alert-danger" *ngIf="addFormError">{{addFormError}}</div>
            <div class="mb-3 d-grid gap-2">
                <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
            </div>
        </div>
    </form>
</kendo-window>