<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h3>Oops! Unauthorized</h3>
            <h1><span>4</span><span>0</span><span>3</span></h1>
        </div>
        <h2>We are sorry, access not granted</h2>
        <p>Please <a [routerLink]="[ '/login' ]">login</a> using different credentials.</p>
        <p><a (click)="goBack()" class="btn btn-primary">Go back</a></p>
    </div>
</div>