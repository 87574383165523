import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Calculator, GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Workbook, WorkbookOptions } from '@progress/kendo-angular-excel-export';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable, timer, Subscription, interval, switchMap } from 'rxjs';
import { LocationData } from 'src/app/models/location.data.model';
import { StatusIcon } from 'src/app/models/status.icon';
import { AlarmComMessage, AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { MapsService } from 'src/app/services/maps.service';
import { AppNotifyService } from 'src/app/services/notify.service';

// All about Google Maps Component
// https://timdeschryver.dev/blog/google-maps-as-an-angular-component
// https://github.com/angular/components/tree/master/src/google-maps#readme
// https://developers.google.com/maps/documentation/javascript/examples/marker-modern

@Component({
  selector: 'app-locations-cenectivity',
  templateUrl: './locations-cenectivity-component.html',
  styleUrls: ['./locations-cenectivity-component.scss']
})
export class LocationsCenectivityComponent implements OnInit, OnDestroy {

  syncSubscription: Subscription;
  activeAccountNr: string;
  customers: { text: string, value: string }[];

  filters: any = {
    eventType: 'ipLink',
    search: '',
    companyId: null,
    statusId: null
  }

  stateFilters: any[] = [
    new StatusIcon(-1),
    new StatusIcon(0),
    new StatusIcon(1),
    new StatusIcon(2)
  ]

  // Map
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChild(MapInfoWindow) mapInfoWindow: MapInfoWindow;
  mapZoom: number = 6;
  mapCenter: google.maps.LatLngLiteral = { lat: 51.509865, lng: 0.118092 };
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 1,
    disableDefaultUI: true
  }
  mapMarkers: any[] = [];
  mapInfoWindowContent: any = {};

  constructor(
    private mapsService: MapsService,
    public auth: AuthService,
    private locationService: LocationService,
    private notify: AppNotifyService,
    private alarmCom: AlarmComService) {
    let _f = localStorage.getItem('LocationsConectivityComponentFilters');
    if (_f) {
      this.filters = JSON.parse(_f);
    }
  }

  ngOnInit(): void {
    this.locationService.getCustomers().subscribe((rez: any) => {
      this.customers = rez;
      this.getData();
    });

    this.syncSubscription = interval(3000).subscribe(() => {
      this.getData();
    });
  }

  ngOnDestroy(): void {
    this.syncSubscription.unsubscribe();
  }

  onFilter() {
    localStorage.setItem('LocationsConectivityComponentFilters', JSON.stringify(this.filters));
    this.mapMarkers = [];
    this.firstLoad = true;
    this.getData();
  }

  rawData:LocationData[] = [];
  getData() {
    this.mapsService.getStoreLocationsConectivity(this.filters).subscribe((rez: LocationData[]) => {
      this.rawData = rez;
      this.proccessLocations(rez);
    });
  }

  private firstLoad: boolean = true;
  private proccessLocations(locations: LocationData[]): void {
    let bounds = new google.maps.LatLngBounds();
    locations.forEach((l: LocationData) => {
      let status = l.status(this.filters.eventType);
      if (this.filters.statusId != null && this.filters.statusId != status) {
        return;
      }

      let marker = this.mapMarkers.find((m: any) => m.info.accountNr == l.accountNr);
      bounds.extend({ lat: l.latitude, lng: l.longitude });

      if (marker) {
        marker.position = { lat: l.latitude, lng: l.longitude };
        marker.title = l.storeName;
        marker.info = l;
        marker.options = this.getMarkerIcon(l);
      } else {
        this.mapMarkers.push({
          position: { lat: l.latitude, lng: l.longitude },
          title: l.storeName,
          info: l,
          clickable: true,
          draggable: false,
          options: this.getMarkerIcon(l)
        });
      }
    });

    // filter out markers that are no longer returned by server
    this.mapMarkers = this.mapMarkers.filter((m: any) => locations.find((l: LocationData) => l.accountNr == m.info.accountNr));

    // First load things
    if (this.firstLoad) {
      if (this.mapMarkers.length > 0) {
        this.map.fitBounds(bounds);
      }
      this.firstLoad = false;
    }
  }

  private getMarkerIcon(data: LocationData): any {
    let icon = new StatusIcon(data.status(this.filters.eventType));
    return { icon: icon.iconPath };
  }

  markerClick(marker: MapMarker, info?: LocationData): void {
    this.activeAccountNr = info?.accountNr || this.activeAccountNr;
    this.locationService.getLocationDetails({ accountNr: this.activeAccountNr }).subscribe((rez: any) => {
      this.mapInfoWindowContent = {
        storeName: rez.storeName,
        storeAddress: rez.storeAddress,
        storePhone: rez.storePhone,
        ipLinkStatus: info.ipLinkStatus,
        ipLinkStatusIcon: new StatusIcon(info.ipLinkStatus),
        testStatus: info.testStatus,
        testStatusIcon: new StatusIcon(info.testStatus)
      };
      this.mapInfoWindow.position = { lat: info.latitude, lng: info.longitude };
      this.mapInfoWindow.open();
    });
  }

  infoWindowClosed(): void {
    this.activeAccountNr = null;
  }

  public exportToExcel(): void {
    const options: WorkbookOptions = this.getExcelExportOptions();
    const workbook = new Workbook(options);
    workbook.toDataURL().then((dataUrl: string) => {
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = 'Locations.xlsx';
      a.click();
    });
  }

  private getExcelExportOptions(): WorkbookOptions {
    return {
      sheets: [{
        name: 'Locations',
        columns: [
          { autoWidth: true },
          { autoWidth: true },
          { autoWidth: true },
          { autoWidth: true }
        ],
        rows: [
          // Define the header row
          {
            cells: [
              { value: 'Acc Nr', background: '#aabbcc' },
              { value: 'Name', background: '#aabbcc' },
              { value: 'HB', background: '#aabbcc' },
              { value: 'PT', background: '#aabbcc' }
            ]
          },
          // Define the data rows
          ...this.rawData.map(dataItem => ({
            cells: [
              { value: dataItem.accountNr },
              { value: dataItem.storeName },
              { value:  this.getIcon(dataItem.ipLinkStatus).text},
              { value: this.getIcon(dataItem.testStatus).text }
            ]
          }))
        ]
      }]
    };
  }
  getIcon(statusId:number) {
    return new StatusIcon(statusId);
  }

}

