import { ChangeDetectorRef, Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DomainCustomer } from '../models/domain.model';

@Injectable()
export class DomainService {

    constructor() { }

    public get domain():string {
        return window.location.hostname.replace(/\./g,'-');;
    }

    public customers: DomainCustomer[] = [
        new DomainCustomer('tjx-webalarm-co', '/assets/images/tjxHeaderLogo.png', 'tjx.webalarm.co'),
        new DomainCustomer('amberstone-webalarm-co', '/assets/images/amberstoneHeaderLogo.png'),
        new DomainCustomer('condor-webalarm-co', '/assets/images/condorHeaderLogo.png'),
        new DomainCustomer(
            'kcp-webalarm-co', '/assets/images/kcpHeaderLogo.png',
            '<a href="tel:+443333113333">+44 (0) 3333 11 3333</a><br>KCP Network, Duke House, Downmill Road, Bracknell, RG12 1QS<br><a href="sales@kcpnetwork.com">sales@kcpnetwork.com</a>',
            ['alarm-map','alarm-states','alarm-conectivity'], 'app/locations'),
        new DomainCustomer('markbayley-webalarm-co', '/assets/images/markbayleyHeaderLogo.png'),
        new DomainCustomer('cngfuels-webalarm-co', '/assets/images/cngfuelsHeaderLogo.png'),
        new DomainCustomer('cargiant-webalarm-co', '/assets/images/cargiantHeaderLogo.png'),
        new DomainCustomer('samatrix-webalarm-co', '/assets/images/samatrixHeaderLogo.jpg')
    ];

    get isGlobal(): boolean {
        return this.activeDomain() == null;
    }

    activeDomain(): DomainCustomer {
        return this.customers.find(c => c.domain == this.domain);
    }

    getDomainHomeRoute():string {
        let domain  = this.activeDomain();
        return domain?.homePath ?? 'app/alarm-map';
    }

    getDomainLogo():string {
        let url = this.customers.find(c => c.domain == this.domain)?.logoUrl;
        return url ?? '/assets/images/logos/ControlfreqWebAlarm.png';
    }

    isFutureEnabled(future: string): boolean {
        let domain  = this.activeDomain();
        if(domain) {
            return domain.disabledFutures.find(f => f === future) ? false: true;
        } else {
        return true;
    }
}

}