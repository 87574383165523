import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pagination, SetAlarm, ArchiveIdPagination } from '../models/alarm.model';

@Injectable()
export class AuditLogService {

    constructor(private http: HttpClient) {}

    getAuditLogs(data: any) {
        return this.http.post(`${environment.apiUrl}auditlog/getAuditLogs`, data);
    }

}