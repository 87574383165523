import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable()
export class AppNotifyService {

    constructor(private _notify: NotificationService) { }

    error(message: string):void {
        this._notify.show({
            content: message,
            type: {
              style: 'error', icon: true
            },
            position: { horizontal: 'right', vertical: 'top' }
          });
    }

    success(message: string):void {
        this._notify.show({
            content: message,
            type: {
              style: 'success', icon: true
            },
            position: { horizontal: 'right', vertical: 'top' }
          });
    }

}