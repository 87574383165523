export class LocationData {
    accountNr: string;
    storeName: string;
    latitude: number;
    longitude: number;
    ipLinkStatus: number;
    testStatus: number;
    isInAlarm: number|undefined;

    constructor(
        accountNr: string,
        storeName: string,
        latitude: number,
        longitude: number,
        ipLinkStatus: number,
        testStatus: number,
        isInAlarm: number|undefined
      ) {
        this.accountNr = accountNr;
        this.storeName = storeName;
        this.latitude = latitude;
        this.longitude = longitude;
        this.ipLinkStatus = ipLinkStatus;
        this.testStatus = testStatus;
        this.isInAlarm = isInAlarm;
      }
  
    status(type:string): number {
      return (type == 'ipLink' ? this.ipLinkStatus : this.testStatus);
    }
  }