import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult, SelectableSettings, SelectionEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DataTable } from 'src/app/models/alarm.model';
import { AlarmComMessage, AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';
import { GridService } from 'src/app/services/grid.service';
import { LocationService } from 'src/app/services/location.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrls: ['./app-users.component.scss']
})
export class AppUsersComponent implements OnInit {

  constructor(
    private userService: UserService,
    public girdService: GridService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private router: Router,
    private dialogService: DialogService,
    private notify: AppNotifyService,
    private alarmCom: AlarmComService,
    private locationService: LocationService) { }

    alarmComSubscription: Subscription;
    gridData: GridDataResult;
    state: State = {
      take: 100,
      skip: 0
    };
    selectableSettings: SelectableSettings = {
      checkboxOnly: false,
      mode: 'single',
      drag: false
    };
    search: string = '';
    company: any = null;
    customers: { text: string, value: string }[];

    public searchModelChanged: Subject<string> = new Subject<string>();
    public searchModelChangeSubscription: Subscription;
  
    ngOnInit(): void {
      // Subscribe to events from AlarmTable
    this.alarmComSubscription = this.alarmCom.get().subscribe((rez: AlarmComMessage) => {
      if (rez.message == 'updateData') {
        this.getData();
      }
    });
      this.locationService.getCustomers().subscribe((rez: any) => {
        this.customers = rez;
      });
      // Get search value
      this.search = localStorage.getItem(`appusers_input_search`) || '';
  
      this.searchModelChangeSubscription = this.searchModelChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(newSearch => {
        this.search = newSearch;
        this.getData();
      });
      this.getData();
    }
  
    ngOnDestroy(): void {
      this.searchModelChangeSubscription.unsubscribe();
    }

    getData() {
      // Save search to localStorage
      localStorage.setItem(`appusers_input_search`, this.search);
  
      let req: any = {
        pageIndex: this.state.skip / this.state.take,
        pageSize: this.state.take,
        orderBy: this.state.sort?.length > 0 ? this.state.sort[0].field : null,
        orderDirection: this.state.sort?.length > 0 ? this.state.sort[0].dir : null,
        search: this.search,
        companyId: this.company?.value
      };
      // Retrive data
      this.userService.getAppUsers(req).subscribe((rez: DataTable) => {
        this.gridData = {
          data: rez.data,
          total: rez.count
        }
      });
    }
  
    dataStateChange(state: DataStateChangeEvent): void {
      this.state = state;
      this.getData();
    }

    selectionChange(event: SelectionEvent): void {
      let id = event.selectedRows[0]?.dataItem.username || null;
      if (id) {
        let url = `/app/app-users/${id}/details`;
        this.router.navigate([url]);
      }
    }

    addNewUser():void {
      this.router.navigate([`/app/app-users/null/details`]);
    }

    /*isOnline (user): boolean {
      let ms = Math.abs(Date.now() - Date.parse(user.lastActiveOn));
      let min = Math.floor((ms/1000)/60);
      return min < 15;
    }*/

    logUserOut(user) {
      const dialog: DialogRef = this.dialogService.open({
        title: 'Confirm User logout',
        content: 'Are you sure you want to signout user? ',
        actions: [
          { text: 'No', value: false, primary: true },
          { text: 'Yes', value: true }
        ]
      });
      dialog.result.subscribe((result: any) => {
        if (result?.value || false) {
          this.userService.logUserOut({ userId: user.username }).subscribe((rez: any) => {
            this.notify.success('User is scheduler for signout.');
            this.getData();
          }, (e: any) => {
            this.notify.error(e.error);
          });
        }
      });
    }

    saveUserStatus(user) {
      const dialog: DialogRef = this.dialogService.open({
        title: 'Confirm',
        content: 'Are you sure you want to do this? ',
        actions: [
          { text: 'No', value: false, primary: true },
          { text: 'Yes', value: true }
        ]
      });
      dialog.result.subscribe((result: any) => {
        if (result?.value || false) {
          this.userService.saveUserStatus({ userId: user.username, status:0 }).subscribe((rez:any) => {
            this.notify.success('User status updated.');
            this.getData();
          }, (e: any) => {
            this.notify.error(e.error);
          });
        }
      });
    }

}
