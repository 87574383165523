import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { UserService } from 'src/app/services/user.service';
import { Observable, Subscription, timer } from 'rxjs';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class MyAppComponent implements OnInit, OnDestroy {

  public userProfile: any;
  public liveDateTime: number;

  clockTimer: Observable<number> = timer(0, 1000);
  clockSubscription: Subscription;
  imAliveTimer: Observable<number> = timer(0, 10000);
  imAliveSubscription: Subscription;

  constructor(
    private user: UserService,
    public auth: AuthService,
    public domain: DomainService
    ) {  }

  ngOnInit() {
    if(this.auth.isLoggedIn()) {
      this.user.imAlive().subscribe();
      this.auth.retriveUserPermissions();

      this.user.getUserProfile().subscribe((rez:any) => {
        this.userProfile = rez;
      });
      
      this.clockSubscription = this.clockTimer.subscribe((seconds) => {
        this.liveDateTime = Date.now();
      });
      this.imAliveSubscription = this.imAliveTimer.subscribe((seconds) => {
        this.user.imAlive().subscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.clockSubscription.unsubscribe();
    this.imAliveSubscription.unsubscribe();
  }
}
