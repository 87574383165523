import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State } from '@progress/kendo-data-query';
import { Pagination, DataTable } from 'src/app/models/alarm.model';
import { GetLocationSenzorsReq, LocationService } from 'src/app/services/location.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { GridService } from 'src/app/services/grid.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomFormValidators } from 'src/app/helpers/customFormValidators';

@Component({
  selector: 'app-site-notifications',
  templateUrl: './site-notifications.component.html',
  styleUrls: ['./site-notifications.component.scss']
})
export class SiteNotificationsComponent implements OnInit {

  constructor(
    private locationService: LocationService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    public gridService: GridService,
    public auth: AuthService,
    private router: Router,
    private formBuilder: UntypedFormBuilder) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        eventTypes: this.formBuilder.array(
          this.eventTypes.map(() => this.formBuilder.control('')),
          CustomFormValidators.multipleCheckboxRequireOne
        )
      });
      this.id = routeParams.id;
      this.getData();
    });
  }

  eventTypes: any[] = [ 
    { name: 'Alarm', value: '01' }, 
    { name: 'Link State', value: '23' },
    { name: 'Mains fail', value: '09' },
    { name: 'Mains restore', value: '10' },
    { name: 'Low battery', value: '07' }
  ];
  form = null;
  addNotificationWindowOpend:boolean = false;
  id: string;
  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };
  addFormError: string = null;

  ngOnInit(): void {
  }

  

  
  get f() { return this.form.controls; }

  getData() {
    let req:any = {
      accountNr: this.id,
      pageIndex: this.state.skip / this.state.take,
      pageSize: this.state.take
    };

    this.locationService.getLocationNotificationEmails(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
    });
  }

  // Grid state changed
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  public delete(id: string): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Confirm delete',
      content: 'Are you sure you want to delete this item? ',
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ]
    });
    dialog.result.subscribe((result: any) => {
      if (result?.value || false) {
        this.locationService.deleteLocationNotificationEmail({ id: id }).subscribe((rez: any) => {
          this.notify.success('Notification email is removed.');
          this.getData();
        }, (e: any) => {
          this.notify.error(e.error);
        });
      }
    });
  }

  public add() {
    // reset form
    this.form.reset();
    this.addFormError = null;
    this.addNotificationWindowOpend = true;
  }

  onAddNewClose() {
    this.addNotificationWindowOpend = false;
  }

  public onSubmit() {
    let data = {
      accountNr: this.id,
      email: this.form.value.email,
      eventTypes: this.eventTypes.filter((val, index) => this.form.value.eventTypes[index] == true).map((val) => val.value)
    };
    this.locationService.saveLocationNotificationEmail(data).subscribe((rez:any) => {
      this.addNotificationWindowOpend = false;
      this.notify.success('Notification email added.');
      this.getData();
      this.addFormError = null;
    }, (e:any) => {
      this.addFormError = e.error;      
    });
  }

  public parseEventTypes(text: String) {
    let tipi = text.split(',').filter((v:String) => v != '');
    let rez: String[] = [];
    tipi.forEach((_tipKey) => {
      let foundTypes = this.eventTypes.filter((val) => val.value == _tipKey);
      if(foundTypes.length == 1) {
        rez.push(foundTypes[0].name);
      }
    });
    return rez.sort().join(', ');
  }
  
}
