import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlarmComService {
    private subject = new Subject<any>();

    push(message: AlarmComMessage) {
        this.subject.next(message);
    }

    get(): Observable<AlarmComMessage> {
        return this.subject.asObservable();
    }
}

export class AlarmComMessage {
    message: string;
    data: any;

    constructor(msg: string, data: any) {
        this.message = msg;
        this.data = data;
    }
}