import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { AlarmService } from 'src/app/services/alarm.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-app-user',
  templateUrl: './app-user.component.html',
  styleUrls: ['./app-user.component.scss']
})
export class AppUserComponent implements OnInit {

  constructor(
    private userService: UserService,
    private alarmService: AlarmService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      this.getData();
    });
  }

  id: string;
  error: string = null;

  ngOnInit(): void {
    
  }

  getData():void {
    // preveri če user obstaja
    if(this.id != 'null') {
      this.userService.getAppUser({ userId: this.id}).subscribe(user => {
        if(!user) {
          this.error = `User ${this.id} not found!`;
          this.notify.error(`User ${this.id} not found!`);
        } else {
          this.error = null;
        }
      }, error => {
        console.log(error);
      });
    }
  }

  onTabSelect(e):void {}

}
