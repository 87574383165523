import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { UserService } from 'src/app/services/user.service';
import { DomainService } from 'src/app/services/domain.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy  {
  loginForm = this.fb.group({
    userName: ['', Validators.required],
    password: ['', Validators.required]
  });
  loginFaild = null;
  currentTime: any = Date.now();

  constructor(
    private fb: UntypedFormBuilder,
    public domain: DomainService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private notify: NotificationService,
    private renderer: Renderer2) {
      
    }

  ngOnInit() {
    this.auth.logout();
    // set locale based on url parametr
    let locale: string = this.route.snapshot.params.language;
    if(locale != null && locale.length == 5 && locale[2] == '-')
      this.auth.setLanguage(locale);
  }

  ngOnDestroy() {
    
  }

  get f() { return this.loginForm.controls; }

  onLoginSubmit() {
    const val = this.loginForm.value;
    this.auth.login(val.userName, val.password)
      .subscribe(
        res => {
            var returnUrl: string = this.route.snapshot.queryParamMap.get('returnUrl');
            this.router.navigateByUrl(returnUrl || new DomainService().activeDomain()?.homePath || 'app/alarm');
            this.notify.show({
              content: `Hello ${val.userName}!`,
              type: { style: 'success', icon: true }
            });
        }, e => {
          this.loginFaild = e.error;
        }
      );
  }

}
