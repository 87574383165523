import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { AppNotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-add-site',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.scss']
})
export class AddSiteComponent implements OnInit {

  constructor(
    private locationService: LocationService,
    private notify: AppNotifyService,
    public auth: AuthService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder) { }

  @ViewChild('typedAddress') typedAddress: any;
  @ViewChild(GoogleMap) map: GoogleMap;
  mapZoom: number = 8;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    disableDefaultUI: true
  }
  location: any = {
    position: { lat: 0, lng: 0 },
    draggable: true
  }
  geocoder: google.maps.Geocoder = new google.maps.Geocoder();
  customers: Array<{ text: string, value: string }>[];
  id: number;

  form = this.formBuilder.group({
    companyId: [{ text: '', value: '' }, [Validators.required]],
    accountNr: ['', [Validators.required, Validators.pattern("^[0-9A-F]{4}$")]],
    imei: ['', [Validators.pattern("^[a-zA-Z0-9]*$")]],
    active: [true],
    storeName: ['', [Validators.required, Validators.maxLength(250)]],
    storeAddress: [{
      place_id: 'custom',
      formatted_address: ''
    }, Validators.maxLength(250)],
    storePhone: ['', Validators.maxLength(50)],
    storeLat: [''],
    storeLng: [''],
    httpRunner: [false]
  });
  get f() { return this.form.controls; }

  adresses: any[] = [];

  ngOnInit(): void {
    this.locationService.getCustomers().subscribe((rez: any) => {
      this.customers = rez;
    });
  }

  geocodeAddress(e: any) {
    //console.log(this.typedAddress._text);
    this.geocoder.geocode({ address: this.typedAddress._text }, (rez) => {
      this.adresses = rez;
    });
  }

  valueNormalizer = (text: Observable<string>) => text.pipe(map((content: string) => {
    this.adresses = [];
    return {
      place_id: 'custom',
      formatted_address: content
    };
  }));


  selectedAddressChanged(e: any) {
    let lat: number = this.form.controls.storeAddress.value.geometry.location.lat();
    let lng: number = this.form.controls.storeAddress.value.geometry.location.lng();
    this.form.controls['storeLat'].setValue(lat);
    this.form.controls['storeLng'].setValue(lng);
    this.location.position = { lat: lat, lng: lng };
    this.map.panTo(this.location.position);
  }

  locationDragEnd(marker: any): void {
    this.location.position.lat = marker.getPosition().lat();
    this.location.position.lng = marker.getPosition().lng();

    this.form.controls['storeLat'].setValue(this.location.position.lat);
    this.form.controls['storeLng'].setValue(this.location.position.lng);
  }

  onSubmit(): void {
    let values = this.form.value;
    values.storeAddress = values.storeAddress.formatted_address;
    values.companyId = values.companyId.value;
    values.isNew = true;
    this.locationService.saveLocationDetails(values).subscribe((rez: any) => {
      this.notify.success('Site saved!');
      this.router.navigate([`/app/sites-management/${values.accountNr}/view`]);
    }, (e: any) => {
      const dialog: DialogRef = this.dialogService.open({
        title: 'Error',
        content: e.error || e.message || JSON.stringify(e),
        actions: [
          { text: 'Ok', primary: true }
        ]
      });
    });
  }

}
