import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { NotificationService } from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AuthService } from "../services/auth.service";

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
    constructor(
        private notify: NotificationService,
        private translate: TranslateService,
        private router: Router,
        private auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error) => {
                if (error.status == 401 || error.status == 403) {
                    this.notify.show({
                        content: `401 Unauthorized`,
                        type: { style: 'error', icon: true },
                        animation: { type: 'fade', duration: 3000 },
                    });
                    this.router.navigate(['/login']);
                } else if (error.status == 0) {
                    this.notify.show({
                        content: `${error.message}`,
                        type: { style: 'error', icon: false },
                        animation: { type: 'fade', duration: 500 },
                    });
                }
                return throwError(error);
            })
        )
    }
}