<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 mb-1" *ngIf="auth.hasPermission('su_user')">
                        <label class="form-label">Customer:</label>
                        <kendo-dropdownlist [data]="customers" [textField]="'text'" [valueField]="'value'"
                            class="form-control" [filterable]="true" formControlName="companyId"
                            [ngClass]="{'is-invalid': f.companyId.invalid && f.companyId.dirty}"></kendo-dropdownlist>
                    </div>
                    <div class="col-lg-6 mb-1">
                        <label class="form-label">Username:</label>
                        <input type="text" class="form-control" formControlName="userId" autocomplete="off"
                            [ngClass]="{'is-invalid': f.userId.invalid && f.userId.dirty}">
                    </div>
                    <div class="col-lg-6 mb-1">
                        <label class="form-label">Password:</label>
                        <input type="password" class="form-control" formControlName="password" autocomplete="off"
                            [ngClass]="{'is-invalid': f.password.invalid && f.password.dirty}">
                            <small *ngIf="!IsNew">Only enter password if you need to change it.</small>
                    </div>
                    <div class="col-lg-6 mb-1">
                        <label class="form-label">User Role:</label>
                        <kendo-dropdownlist [data]="userRoles" [textField]="'text'" [valueField]="'value'"
                            class="form-control" [filterable]="false" formControlName="userRole"
                            [ngClass]="{'is-invalid': f.userRole.invalid && f.userRole.dirty}"></kendo-dropdownlist>
                    </div>
                    <div class="col-lg-6 mb-1">
                        <label class="form-label">Email:</label>
                        <input type="text" class="form-control" formControlName="email" autocomplete="off"
                            [ngClass]="{'is-invalid': f.email.invalid && f.email.dirty}">
                    </div>
                    <div class="col-lg-12 mt-3">
                        <button type="submit" class="btn btn-primary w-100 w-lg-auto" [disabled]="!form.valid && auth.hasPermission('strankeadmin')">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>