<kendo-tabstrip style="height: calc(100%)" class="mt-3">
    <kendo-tabstrip-tab [title]="'Add Site'" [selected]="true">
        <ng-template kendoTabContent>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="row">
        
        
                                        <div class="col-lg-12 mb-1">
                                            <label class="form-label">Customer:</label>
                                            <kendo-dropdownlist [data]="customers" [textField]="'text'" [valueField]="'value'"
                                                class="form-control" [filterable]="true" formControlName="companyId"
                                                [ngClass]="{'is-invalid': f.companyId.invalid && f.companyId.dirty}"></kendo-dropdownlist>
                                        </div>
                                        <div class="col-lg-12 mb-1">
                                            <label class="form-label">Site ID:</label>
                                            <input type="text" class="form-control" formControlName="accountNr"
                                                [ngClass]="{'is-invalid': f.accountNr.invalid && f.accountNr.dirty}">
                                        </div>
                                        <div class="col-lg-12 mb-1">
                                            <label class="form-label">IMEI:</label>
                                            <input type="text" class="form-control" formControlName="imei"
                                                [attr.disabled]="auth.hasPermission('su_user') ? null : true"
                                                [ngClass]="{'is-invalid': f.imei.invalid && f.imei.dirty}">
                                        </div>
                                        <div class="col-lg-12 mb-1">
                                            <label class="form-label">Site Name:</label>
                                            <input type="text" class="form-control" formControlName="storeName"
                                                [ngClass]="{'is-invalid': f.storeName.invalid && f.storeName.dirty}">
                                        </div>
                                        <div class="col-lg-12 mb-1">
                                            <label class="form-label">Site Phone number:</label>
                                            <input type="text" class="form-control" formControlName="storePhone"
                                                [ngClass]="{'is-invalid': f.storePhone.invalid && f.storePhone.dirty}">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="row">
                                        <div class="col-lg-12 mb-1">
                                            <fa-icon kendoTooltip icon="question-circle"
                                                title="Please include country to focus search (Germany Leipzig Thomaskirchhof 18)"
                                                style="float: right;"></fa-icon>
                                            <label class="form-label">Site Address:</label>
                                            <kendo-combobox [data]="adresses" class="form-control" #typedAddress
                                                [allowCustom]="true" formControlName="storeAddress"
                                                (input)="geocodeAddress($event)" (change)="selectedAddressChanged($event)"
                                                [textField]="'formatted_address'" [valueField]="'place_id'"
                                                [valueNormalizer]="valueNormalizer"></kendo-combobox>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-lg-6 mb-1"><label class="form-label">Latitude:</label>
                                                <input type="number" class="form-control" formControlName="storeLat"
                                                    [ngClass]="{'is-invalid': f.storeLat.invalid && f.storeLat.dirty}">
                                            </div>
                                            <div class="col-lg-6 mb-1"><label class="form-label">Longtitude:</label>
                                                <input type="number" class="form-control" formControlName="storeLng"
                                                    [ngClass]="{'is-invalid': f.storeLng.invalid && f.storeLng.dirty}">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mb-1">
                                            <kendo-switch formControlName="active" class="me-3"></kendo-switch>
                                            <label class="form-label">Test mode</label>
                                        </div>
                                        <div class="col-lg-12 mb-1" *ngIf="f.companyId.value.value == '10'">
                                            <kendo-switch formControlName="httpRunner" class="me-3"></kendo-switch>
                                            <label class="form-label"> Report events to HTTP</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mb-1">
                                        <button type="submit" class="btn btn-primary w-100 w-lg-auto"
                                            [disabled]="!form.valid">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 mb-1">
                            <google-map width="100%" height="300px" [zoom]="mapZoom" [options]="mapOptions"
                                [center]="location.position">
                                <map-marker #marker="mapMarker" [position]="location.position" title="Site Location"
                                    [options]="location" (mapDrag)="locationDragEnd(marker)"></map-marker>
                            </google-map>
                        </div>
                    </div>
                </div>
            </form>
            <div kendoDialogContainer></div>
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>