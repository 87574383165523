<div *ngIf="!error" class="mt-3" style="height: calc(100% - 510px)">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <h4 *ngIf="id == 'null'">Add User</h4>
                <h4 *ngIf="id != 'null'">Edit User {{id}}</h4>
            </div>
        </div>
    </div>

    <kendo-tabstrip (tabSelect)="onTabSelect($event)" style="height: calc(100%)">
        <kendo-tabstrip-tab [title]="'Edit User'" [selected]="true">
            <ng-template kendoTabContent>
                <app-user-edit></app-user-edit>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Event Log'" *ngIf="id != 'null'">
            <ng-template kendoTabContent>
                <app-audit-log></app-audit-log>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>

<div *ngIf="error" class="mt-3" style="height: calc(100% - 510px)">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 alert alert-danger">
                {{error}}
            </div>
        </div>
    </div>
</div>