import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) { }

    getUserProfile() {
        return this.http.get(`${environment.apiUrl}user/getUserProfile`);
    }

    getUserPermissions() {
        return this.http.get(`${environment.apiUrl}user/getUserPermissions`);
    }

    getAppUsers(data) {
        return this.http.post(`${environment.apiUrl}user/getAppUsers`, data);
    }

    getAppUser(data) {
        return this.http.post(`${environment.apiUrl}user/getAppUser`, data);
    }

    saveAppUser(data) {
        return this.http.post(`${environment.apiUrl}user/saveAppUser`, data);
    }

    logUserOut(data) {
        return this.http.post(`${environment.apiUrl}user/logUserOut`, data);
    }

    saveUserStatus(data) {
        return this.http.post(`${environment.apiUrl}user/saveUserStatus`, data);
    }

    imAlive() {
        return this.http.get(`${environment.apiUrl}user/imAlive`);
    }



    startPasswordReset(data) {
        return this.http.post(`${environment.apiUrl}user/startPasswordReset`, data);
    }

    validatePasswordResetToken(data) {
        return this.http.post(`${environment.apiUrl}user/validatePasswordResetToken`, data);
    }

    setNewPasswordReset(data) {
        return this.http.post(`${environment.apiUrl}user/setNewPasswordReset`, data);
    }

}