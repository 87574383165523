export class AlarmIcon {
    isInAlarm: boolean;
    private basePath = '/assets/images/marker/';

    constructor(nrOfAlarms: number) {
        this.isInAlarm = nrOfAlarms > 0;
    }

    get value(): boolean {
        return this.isInAlarm;
    }

    get iconPath(): string {
        if(this.isInAlarm) {
            return `${this.basePath}red.gif`; 
        } else {
            return `${this.basePath}green.gif`;
        }
    }

    get text(): string {
        if(this.isInAlarm) {
            return `Alarm`; 
        } else {
            return `Ok`;
        }
    }
} 