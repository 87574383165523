<div *ngIf="alarmTypes == 1" class="container-fluid px-3 py-2 bg-light">
    <div class="row">
        <div class="col-lg-2 mb-1"><kendo-datetimepicker [(value)]="fromDate"></kendo-datetimepicker></div>
        <div class="col-lg-2 mb-1"><kendo-datetimepicker [(value)]="toDate"></kendo-datetimepicker></div>
        <div class="col-lg-1 mb-1"><button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="getData()">Search</button></div>
        <div class="col"></div>
        <div class="col-lg-1 mb-1 align-self-end">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="exportToExcel(grid)"  *ngIf="auth.hasPermission('viewizpisi')">Export</button>
        </div>
    </div>
</div>

<kendo-grid #grid="kendoGrid" [data]="gridData" [ngStyle]="{'height': alarmTypes == 1 ? 'calc(100% - 180px)':'calc(100%)'}" resizable="true"
    [pageable]="true" [pageSize]="state.take" [skip]="state.skip" 
    [sortable]="{ allowUnsort:true, mode: 'single' }" [sort]="sort" (sortChange)="sortChange($event)"
    [filterable]="false" (dataStateChange)="dataStateChange($event)" class="dataGird k-grid-ignore-click"
    (columnResize)="girdService.onColResize(gridName, $event)" >
    <kendo-grid-column field="accountNr" title="Acc. nr." class="text-center" [width]="girdService.getColWidth(gridName,0,'60px')"></kendo-grid-column>
    <kendo-grid-column field="storeName" title="Site name" [width]="girdService.getColWidth(gridName,1,'400px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span (click)="showStoreOnMap(dataItem.accountNr)" *ngIf="showOnMapIcon && dataItem.hasMapLocation == 1" class="hover trimText">{{dataItem.storeName}}</span>
            <span *ngIf="!showOnMapIcon || dataItem.hasMapLocation == 0" class="trimText">{{dataItem.storeName}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="storeAddress" title="Site address" [width]="girdService.getColWidth(gridName,2)">
        <ng-template kendoGridCellTemplate let-dataItem><span class="trimText">{{dataItem.storeAddress}}</span></ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="alarmType" title="Alarm type" [width]="girdService.getColWidth(gridName,3,'200px')"></kendo-grid-column>
    <kendo-grid-column field="zoneName" title="Description" [width]="girdService.getColWidth(gridName,10,'160px')"></kendo-grid-column>
    <kendo-grid-column field="dateTime" title="Date" [width]="girdService.getColWidth(gridName,4,'100px')">
        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.dateTime| date:'mediumDate'}}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="dateTime" title="Time (UTC)" [width]="girdService.getColWidth(gridName,5,'100px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.dateTime| date:'mediumTime'}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="HB" [width]="girdService.getColWidth(gridName,6,'60px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <img [src]="getIcon(dataItem.ipLinkStatus).iconPath" [title]="'Heartbeat ' + getIcon(dataItem.ipLinkStatus).text">
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="PT" [width]="girdService.getColWidth(gridName,7,'60px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <img [src]="getIcon(dataItem.linkStatus).iconPath" [title]="'Periodic test ' + getIcon(dataItem.linkStatus).text">
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="ARM" [width]="girdService.getColWidth(gridName,8,'60px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <fa-icon *ngIf="dataItem.status == 0" [icon]="'question'" title="UNKNOWN" class="text-secondary"></fa-icon>
            <fa-icon *ngIf="dataItem.status == 1" [icon]="'unlock'" title="DISARMED" class="text-success"></fa-icon>
            <fa-icon *ngIf="dataItem.status == 2" [icon]="'lock'" title="ARMED" class="text-danger"></fa-icon>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Purge" [headerStyle]="{'text-align': 'center'}" [width]="girdService.getColWidth(gridName,9,'60px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-center">
                <fa-icon *ngIf="auth.hasPermission('master')" icon="trash-alt" class="text-danger hover" (click)="archiveAlarm(dataItem.eventId)"></fa-icon>
            </div>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="export.xlsx">
        <kendo-excelexport-column field="accountNr" title="Account No."></kendo-excelexport-column>
        <kendo-excelexport-column field="storeName" title="Site name"></kendo-excelexport-column>
        <kendo-excelexport-column field="storeAddress" title="Site address"></kendo-excelexport-column>
        <kendo-excelexport-column field="alarmType" title="Alarm type"></kendo-excelexport-column>
        <kendo-excelexport-column field="dateTime" title="Event date"></kendo-excelexport-column>
        <kendo-excelexport-column field="linkStatus" title="Link status"></kendo-excelexport-column>
        <kendo-excelexport-column field="status" title="Status"></kendo-excelexport-column>
    </kendo-grid-excel>
</kendo-grid>


<div kendoDialogContainer></div>