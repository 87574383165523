import { Injectable } from '@angular/core';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Pagination, SetAlarm, ArchiveIdPagination } from '../models/alarm.model';
import { map, Observable } from 'rxjs';
import { LocationData } from '../models/location.data.model';

@Injectable()
export class MapsService {

    constructor(private http: HttpClient) {}

    getStoreLocationsWithProperties(data: any):Observable<LocationData[]> {
        return this.http.post<LocationData[]>(`${environment.apiUrl}maps/getStoreLocationsWithProperties`, data)
        .pipe(map(response => {
            return response.map(item => new LocationData(
              item.accountNr,
              item.storeName,
              item.latitude,
              item.longitude,
              item.ipLinkStatus,
              item.testStatus,
              item.isInAlarm
            ));
          }));
    }

    getStoreLocationsConectivity(data: any):Observable<LocationData[]> {
        return this.http.post<LocationData[]>(`${environment.apiUrl}maps/getStoreLocationsConectivity`, data)
        .pipe(map(response => {
            return response.map(item => new LocationData(
              item.accountNr,
              item.storeName,
              item.latitude,
              item.longitude,
              item.ipLinkStatus,
              item.testStatus,
              null
            ));
          }));
    }

}