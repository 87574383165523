<section id="header" class="container-fluid">
  <div class="row align-items-center">
    <div class="col-6 p-3">
      <a class="navbar-brand" href="/"><img [src]="domain.getDomainLogo()" style="max-height: 30px;"></a>
      <div *ngIf="!domain.isGlobal" class="d-inline-block border-start border-2 ps-5 ms-5">
        <img src="/assets/images/logos/ControlfreqPowerdByDark.png" style="max-height: 30px;">
      </div>
    </div>
    <div class="col-6 text-end">
      <div class="fw-light">
        {{userProfile?.username}} | <a [routerLink]="[ '/login' ]" class="link-underline link-underline-opacity-0">{{'component.login.logout'|translate}}</a>
        <button class="navbar-toggler d-lg-none ms-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
          <fa-icon [icon]="['fas', 'bars']"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</section>

<div *ngIf="false"><code>{{auth.getAllPermissions()}}</code></div>

<nav class="navbar navbar-expand-lg px-3 mt-2 mb-2">
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav me-auto">
      <li class="nav-item" *ngIf="domain.isFutureEnabled('alarm')">
        <a [routerLink]="[ '/app/alarm' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.alarmTable.title'|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="domain.isFutureEnabled('alarm-map')">
        <a [routerLink]="[ '/app/alarm-map' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.alarmMap.title'|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="domain.isFutureEnabled('alarm-states')">
        <a [routerLink]="[ '/app/alarm-states' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.alarmStates.title'|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="domain.isFutureEnabled('alarm-conectivity')">
        <a [routerLink]="[ '/app/alarm-conectivity' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.alarmConectivity.title'|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="domain.isFutureEnabled('locations')">
        <a [routerLink]="[ '/app/locations' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.locations.title'|translate}} & {{'component.locationHistory.title'|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="auth.hasPermission('strankeedit')">
        <a [routerLink]="[ '/app/sites-management' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.siteManagement.title'|translate}}</a>
      </li>
      <li class="nav-item" *ngIf="auth.hasPermission('strankeedit')">
        <a [routerLink]="[ '/app/app-users' ]" class="nav-link btn btn-light rounded-pill px-3 me-3" routerLinkActive="active">{{'component.appUsers.title'|translate}}</a>
      </li>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>