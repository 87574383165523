<div class="container-fluid px-3 py-2 bg-light">
    <div class="row">
        <div class="col"></div>
        <div class="col-lg-1 mb-1 align-self-end">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="add()"
                *ngIf="auth.hasPermission('strankeedit')">Add</button>
        </div>
        <div class="col-lg-1 mb-1 align-self-end">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="exportToExcel(grid)"
                *ngIf="auth.hasPermission('viewizpisi')">Export</button>
        </div>
    </div>
</div>

<kendo-grid #grid="kendoGrid" [data]="gridData" [pageSize]="state.take" [skip]="state.skip" [sortable]="true"
    [pageable]="true" [filterable]="false" (dataStateChange)="dataStateChange($event)"
    class="dataGird k-grid-ignore-click" style="height: calc(100% - 40px);  min-height: 400px;" resizable="true"
    (columnResize)="gridService.onColResize('siteDetectors', $event)">
    <kendo-grid-column field="email" title="Email" [width]="gridService.getColWidth('siteNotifications',0)">
    </kendo-grid-column>
    <kendo-grid-column title="Event types" [width]="gridService.getColWidth('siteNotifications',2)">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{parseEventTypes(dataItem.tip)}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="gridService.getColWidth('siteNotifications',1,'100px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="text-center">
                <fa-icon *ngIf="auth.hasPermission('master')" icon="trash-alt" class="text-danger hover"
                    (click)="delete(dataItem.id_)"></fa-icon>
            </div>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="export.xlsx"></kendo-grid-excel>
</kendo-grid>

<div kendoDialogContainer></div>

<kendo-window title="Add notification" *ngIf="addNotificationWindowOpend" width="350px" (close)="onAddNewClose()">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="container-fluid">
            <div class="mb-3">
                <label class="form-label">Email:</label>
                <input type="email" class="form-control" formControlName="email"
                    [ngClass]="{'is-invalid': f.email.invalid && f.email.dirty}">
            </div>
            <div class="mb-3" formArrayName="eventTypes">
                <label class="form-label">Event types:</label>
                <div *ngFor="let tip of form.controls.eventTypes.controls; let i = index;">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" [formControlName]="i"
                            [value]="eventTypes[i].value" id="eventTypes-{{[i]}}">
                        <label class="form-check-label" for="eventTypes-{{[i]}}">
                            {{eventTypes[i].name}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger" *ngIf="addFormError">{{addFormError}}</div>
            <div class="mb-3 d-grid gap-2">
                <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Save</button>
            </div>
        </div>
    </form>
</kendo-window>