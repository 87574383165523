import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { AlarmComMessage, AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  constructor(
    private userService: UserService,
    private locationService: LocationService,
    private notify: AppNotifyService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public auth: AuthService,) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
      this.IsNew = this.id == 'null';
      this.getData();
    });
  }

  IsNew: boolean = false;
  public id: string = null;
  form:any;
  get f() { return this.form.controls; }
  customers: { text: string, value: string }[];
  userRoles: { text: string, value: string }[] = [ {text:'User', value:'User'}, {text:'Admin', value:'Admin'} ];

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      userId: [{ value:'', disabled: !this.IsNew }, [Validators.required]],
      companyId: [{value: null, disabled: !this.IsNew}, [Validators.required]],
      password: ['', [Validators.minLength(8)]],
      email: ['', [Validators.required, Validators.email /*Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")*/]],
      userRole: [{value: null}, [Validators.required]]
    });
    if(this.IsNew) {
      this.form.controls.password.setValidators([Validators.required]);
      this.form.controls.password.updateValueAndValidity();
      this.form.controls.userRole.setValidators([Validators.required]);
      this.form.controls.userRole.updateValueAndValidity();
    }
  }

  getData(): void {
    this.locationService.getCustomers().subscribe((rez: any) => {
      this.customers = rez;
      if(this.IsNew) {
        this.form?.controls['companyId'].setValue({value: this.auth.getCompany()});
      }
    });

    if (!this.IsNew) {
      this.userService.getAppUser({ userId: this.id }).subscribe((rez: any) => {
        if(rez) {
          this.form.controls['userId'].setValue(rez.username);
          this.form.controls['email'].setValue(rez.email);
          this.form.controls['companyId'].setValue({value: rez.companyId});
          this.form.controls['userRole'].setValue({value: rez.userRole})
          this.form.controls['userId'].disable();
          this.form.controls['companyId'].disable();
          this.form.controls.password.setValidators();
        }
      });
    } else {
      this.form?.controls['userId'].setValue('');
      this.form.controls['email'].setValue('');
      this.form?.controls['companyId'].setValue(null);
      this.form?.controls['userRole'].setValue(null)
      this.form?.controls['userId'].enable();
      this.form?.controls['companyId'].enable();
      this.form?.controls.password.setValidators([Validators.required]);
    }
    this.form?.controls.password.updateValueAndValidity();
  }

  onSubmit(): void {
    let values = {
      userId: this.f.userId.value,
      email: this.f.email.value,
      companyId: this.f.companyId.value?.value || this.auth.getCompany(),
      isNew: this.IsNew,
      userRole: this.f.userRole.value.value,
      password: this.f.password.value
    };
    this.userService.saveAppUser(values).subscribe((rez: any) => {
      this.notify.success('User saved!');
      this.router.navigate([`/app/app-users/${values.userId}/details`]);
      this.alarmCom.push(new AlarmComMessage('updateData', null));
    }, (e: any) => {
      this.notify.error(e.error || e.message);
    });
  }

}
