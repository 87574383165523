<div class="row px-3 pb-2">
    <div class="col-lg-3 mb-1">
        <input type="text" class="form-control form-control-sm" [(ngModel)]="filters.search"
            (ngModelChange)="onFilter()" placeholder="Search by AccNr...">
    </div>
    <div class="col-lg-3 mb-1" *ngIf="auth.hasPermission('su_user')">
        <kendo-dropdownlist [data]="customers" [textField]="'text'" [valueField]="'value'" class="" [filterable]="true"
            [(ngModel)]="filters.companyId" [valuePrimitive]="true" style="min-width: 200px;"
            [defaultItem]="{ text: 'Any customer', value: null }" (valueChange)="onFilter()">
        </kendo-dropdownlist>
    </div>
    <div class="col-lg-3 mb-1">
        <kendo-dropdownlist [data]="stateFilters" [textField]="'text'" [valueField]="'value'" class=""
            [filterable]="true" [(ngModel)]="filters.statusId" [valuePrimitive]="true" style="min-width: 200px;"
            [defaultItem]="{ text: 'Any alarm status', value: null }" (valueChange)="onFilter()">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
                <span>
                    <img [src]='dataItem.iconPath'> {{ dataItem.text }}
                </span>
            </ng-template>
        </kendo-dropdownlist>
    </div>
</div>

<div class="row" style="height: calc(100% - 110px);">
    <div class="col-lg-6" style="height: calc(100% - 110px);">
        <google-map width="100%" height="100%" [zoom]="mapZoom" [center]="mapCenter" [options]="mapOptions">

            <map-marker #marker="mapMarker" *ngFor="let m of mapMarkers" [position]="m.position" [label]="m.label"
                [title]="m.title" [options]="m.options" (mapClick)="markerClick(marker, m.info)">
            </map-marker>

            <map-info-window (closeclick)="infoWindowClosed()">
                <div style="min-width: 200px;">
                    <h4>{{mapInfoWindowContent?.accountNr}}</h4>
                    <div class="container-fluid g-0">
                        <div class="row g-0">
                            <div class="col-4"><b>Site Name:</b></div>
                            <div class="col">{{mapInfoWindowContent?.storeName}}</div>
                        </div>
                        <div class="row g-0">
                            <div class="col-4"><b>Address:</b></div>
                            <div class="col">{{mapInfoWindowContent?.storeAddress}}</div>
                        </div>
                        <div class="row g-0">
                            <div class="col-4"><b>Phone:</b></div>
                            <div class="col"><a
                                    href="tel:{{mapInfoWindowContent?.phoneNumber}}">{{mapInfoWindowContent?.storePhone}}</a>
                            </div>
                        </div>
                        <div class="row g-0">
                            <div class="col-4"><b>Nr. of Alarm's:</b></div>
                            <div class="col">{{mapInfoWindowContent?.alarmStateCount}}</div>
                        </div>
                    </div>
                </div>
            </map-info-window>

        </google-map>
    </div>
    <div class="col-lg-6" style="height: calc(100% - 110px);">
        <app-alarm [showOnMapIcon]="true" ></app-alarm>
    </div>
</div>