import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomainService } from './services/domain.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    translate: TranslateService,
    domain: DomainService,
    renderer: Renderer2) {
    renderer.addClass(document.body, 'c-' + domain.domain);
    translate.addLangs(['en', 'sl']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}