import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { Pagination, DataTable } from 'src/app/models/alarm.model';
import { StatusIcon } from 'src/app/models/status.icon';
import { AlarmService } from 'src/app/services/alarm.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { AuthService } from 'src/app/services/auth.service';
import { GridService } from 'src/app/services/grid.service';

@Component({
  selector: 'app-location-events',
  templateUrl: './location-events.component.html',
  styleUrls: ['./location-events.component.scss']
})
export class LocationEventsComponent implements OnInit {

  constructor(
    private alarmService: AlarmService,
    public auth: AuthService,
    private notify: NotificationService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private alarmCom: AlarmComService,
    private activatedRoute: ActivatedRoute,
    public gridService: GridService,
    private router: Router) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      if (routeParams.id) {
        this.id = routeParams.id;
        this.getData();
      }
    });
    this.activatedRoute.params.subscribe(routeParams => {
      if (routeParams.id) {
        this.id = routeParams.id;
        this.getData();
      }
    });
  }

  id: string;
  fromDate: Date = moment().subtract(1, 'months').toDate();
  toDate: Date = moment().add(1, 'days').toDate();
  alarmType: any;
  alarmTypesOptions: object[] = [
    { text: 'Alert', value: '01' },
    { text: 'Disarm', value: '02' },
    { text: 'Arm', value: '03' },
    { text: 'Test', value: '04' },
    { text: 'Event reset', value: '05' },
    { text: 'Failure', value: '06' },
    { text: 'Connection', value: '23' },
    { text: 'Schedule', value: '25' },

    { text: 'IP com fail/restore', value: '26' },
    { text: 'Mains fail/restore', value: '09' },
    { text: 'Low battery', value: '07' },
  ];

  gridData: GridDataResult;
  state: State = {
    take: 100,
    skip: 0
  };
  sort: SortDescriptor[] = [
    {
      field: 'DateTime',
      dir: 'desc'
    }
  ];

  ngOnInit(): void {

  }

  getData() {
    let req: any = {
      accountNr: this.id,
      pageIndex: this.state.skip / this.state.take,
      pageSize: this.state.take,
      orderBy: this.sort?.length > 0 ? this.sort[0].field : 'DateTime',
      orderDirection: this.sort?.length > 0 ? this.sort[0].dir : 'desc',
      alarmType: this.alarmType?.value,
      fromDate: this.fromDate,
      toDate: this.toDate
    };
    this.alarmService.getEvents(req).subscribe((rez: DataTable) => {
      this.gridData = {
        data: rez.data,
        total: rez.count
      }
    }, e => {
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.getData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getData();
  }

  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  archiveAlarm(id: string): void {

  }

  getIcon(statusId:number) {
    return new StatusIcon(statusId);
  }

}
