<div class="container-fluid px-3 py-2 bg-light">
    <div class="row">
        <div class="col-lg-2 mb-1">
            <kendo-datetimepicker [(value)]="fromDate" class="form-control"></kendo-datetimepicker>
        </div>
        <div class="col-lg-2 mb-1">
            <kendo-datetimepicker [(value)]="toDate" class="form-control"></kendo-datetimepicker>
        </div>
        <div class="col-lg-1 mb-1">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="getData()">Search</button>
        </div>
        <div class="col"></div>
        <div class="col-lg-1 align-self-end text-end">
            <button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" *ngIf="auth.hasPermission('viewizpisi')"
                (click)="exportToExcel(grid)">Export</button></div>
    </div>
</div>
<kendo-grid #grid="kendoGrid" [data]="gridData" [pageable]="true" [pageSize]="state.take" [skip]="state.skip" resizable="true" (columnResize)="gridService.onColResize('auditlog', $event)"
        [sortable]="{ allowUnsort:true, mode: 'single' }" [sort]="sort" (sortChange)="sortChange($event)" style="height: calc(100% - 40px);  min-height: 400px;"
        [filterable]="false" (dataStateChange)="dataStateChange($event)" class="dataGird k-grid-ignore-click">
        <kendo-grid-column field="userName" title="User name" [width]="gridService.getColWidth('auditlog',0,'100px')"></kendo-grid-column>
        <kendo-grid-column field="dateTime" title="Date time (UTC)" [width]="gridService.getColWidth('auditlog',1,'200px')">
            <ng-template kendoGridCellTemplate let-item>{{item.dateTime| date:'medium'}}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="event" title="Event" [width]="gridService.getColWidth('auditlog',2,'300px')"></kendo-grid-column>
        <kendo-grid-column *ngIf="!screen.isMobile" field="message" title="Message" [width]="gridService.getColWidth('auditlog',3)"></kendo-grid-column>
        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
        </ng-template>
        <kendo-grid-excel fileName="export.xlsx"></kendo-grid-excel>
    </kendo-grid>