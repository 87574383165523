import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridDataResult, DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State } from '@progress/kendo-data-query';
import { Pagination, DataTable } from 'src/app/models/alarm.model';
import { GetLocationSenzorsReq, LocationService } from 'src/app/services/location.service';
import { AlarmComService } from 'src/app/services/alarmcom.service';
import { GridService } from 'src/app/services/grid.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppNotifyService } from 'src/app/services/notify.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CustomFormValidators } from 'src/app/helpers/customFormValidators';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss']
})
export class SiteSettingsComponent implements OnInit {

  id: String;

  constructor(
    private activatedRoute: ActivatedRoute,
    private locationApi: LocationService,
    private dialogService: DialogService) {
    this.activatedRoute.parent.params.subscribe(routeParams => {
      this.id = routeParams.id;
    });
  }

  ngOnInit(): void {

  }

  refreshSim() {
    this.taskStatus = undefined;
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: "Are you sure to initiate a REFRESH task on SIM card?",
      actions: [{ text: "No" }, { text: "Yes", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        if(result.text == 'Yes') {
          this.refreshSimNow();
        }
      }
    });
  }

  private refreshTaskubscription: Subscription;
  refreshTaskId: number | undefined = undefined;
  private refreshSimNow() {
    this.locationApi.resetSIM(this.id).subscribe({
      next: (value: any) => {
        this.refreshTaskId = value.result.requestNumber;
        this.checkForRefreshresult();
        this.dialogService.open({
          title: "SUCCESS",
          content: "SIM Refresh initialised... Check the status below the button.",
          actions: [{ text: "Ok" }],
          width: 450,
          height: 200,
        });
      },
      error: (error: any) => {
        this.dialogService.open({
          title: "ERROR",
          content: error.error.Message,
          actions: [{ text: "Close" }],
          width: 450,
          height: 200,
        });
      }
    });
  }

  public taskStatus: any = undefined;
  private checkForRefreshresult() {
    if(this.refreshTaskId) {
      this.refreshTaskubscription = interval(1000).subscribe(() => {
        this.locationApi.getResetSIMStatus(this.refreshTaskId).subscribe({
          next: (value:any) => {
            this.taskStatus = value.result.customerRequestBatchRequests[0];
            if(this.taskStatus.status == 'complete') {
              this.refreshTaskubscription.unsubscribe();
            }
          },
          error: (error:any) => {
            console.log(error);
          },
        });
      });
    }
  }
}


/*
{
    "isSuccess": true,
    "result": {
        "requestNumber": 1914745
    },
    "errorMessage": null,
    "errorCode": null
}

{ "isSuccess": true, "result": { "customerRequestBatchRequests": [ { "id": 33117423, "sim": "8931081321068698521", "status": "complete", "errorMessage": "", "created": "2024-06-21T11:32:55+01:00", "action": "sim_refresh" } ] }, "errorMessage": null, "errorCode": null }

*/

