<div class="container-fluid px-3 py-2 bg-light">
    <div class="row">
        <div class="col-lg-2 mb-1"><input type="text" [ngModel]="search" (ngModelChange)='searchModelChanged.next($event)'
                 class="form-control form-control-sm" placeholder="Search by Acc, Name or Address"></div>
        <div class="col-lg-2 mb-1" *ngIf="auth.hasPermission('su_user')">
            <kendo-dropdownlist [data]="customers" [textField]="'text'" [valueField]="'value'" 
                [filterable]="true" [(ngModel)]="company" [defaultItem]="{ text: 'Select Customer', value: null }"
                (valueChange)="getData(true)">
            </kendo-dropdownlist>
        </div>
        <div class="col-lg-1 mb-1"><button type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="getData(true)">Search</button></div>
        <div class="col"></div>
        <div class="col-lg-1 mb-1 align-self-end text-end" *ngIf="addNewSiteVisible && auth.hasPermission('su_user')"><button
                type="button" class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="addLocation()">Add new site</button></div>
    </div>
</div>

<kendo-grid [data]="gridData" [pageSize]="state.take" [skip]="state.skip" [sortable]="true" [pageable]="true"
    resizable="true" [filterable]="false" (dataStateChange)="dataStateChange($event)"
    class="dataGird k-grid-ignore-click" [selectable]="selectableSettings" (selectionChange)="selectionChange($event)"
    (columnResize)="girdService.onColResize('locations', $event)">
    <kendo-grid-column field="accountNr" title="Acc. nr."
        [width]="girdService.getColWidth('locations',0,'60px')"></kendo-grid-column>
    <kendo-grid-column field="storeName" title="Site Name"
        [width]="girdService.getColWidth('locations',1,'400px')"></kendo-grid-column>
    <kendo-grid-column field="storeAddress" title="Site Address"
        [width]="girdService.getColWidth('locations',2)"></kendo-grid-column>
    <kendo-grid-column field="companyName" title="Customer Name"
        [width]="girdService.getColWidth('locations',3,'300px')"></kendo-grid-column>
    <kendo-grid-column field="active" title="Test" [width]="girdService.getColWidth('locations',4,'70px')">
        <ng-template kendoGridCellTemplate let-dataItem>
            <code *ngIf="dataItem.active == 1">ON</code>
            <code *ngIf="dataItem.active == 0">OFF</code>
        </ng-template>
    </kendo-grid-column>
    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-page-sizes [pageSizes]="[100,500,1000]"></kendo-pager-page-sizes>
    </ng-template>
</kendo-grid>

<div style="height: calc(100% - 440px);"><router-outlet></router-outlet></div>
