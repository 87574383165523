<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="row mb-3 d-none d-lg-flex">
        <div class="col-lg-3"></div>
        <div class="col-lg-2">
            <b>Opening time</b>
        </div>
        <div class="col-lg-2">
            <b>Closing time</b>
        </div>
    </div>
    <div class="row mb-3" *ngFor="let item of form.controls; let n=index">
        <div class="col-lg-3 text-lg-end d-none d-lg-block"><label>{{days[n]}}</label></div>
        <div class="col-lg-2 mb-1">
            <label class="d-lg-none"><b>{{days[n]}}</b> opening time</label>
            <kendo-timepicker [formControl]="item.controls.set"></kendo-timepicker>
        </div>
        <div class="col-lg-2 mb-1">
            <label class="d-lg-none"><b>{{days[n]}}</b> closing time</label>
            <kendo-timepicker [formControl]="item.controls.unset"></kendo-timepicker>
        </div>
        <div class="col-lg-1 mb-1">
            <button type="button" class="btn btn-sm" (click)="clearInput(item.controls)"
                *ngIf="auth.hasPermission('strankeedit')"><fa-icon icon="times"></fa-icon></button></div>
        <div *ngIf="item.hasError('startAndStopRequired')" class="col-lg-12 text-danger">Please enter opening and
            closing time!</div>
        <div *ngIf="item.hasError('stopIsGreaterThanStart')" class="col-lg-12 text-danger">Closing time must be
            greater than opening time!</div>
    </div>

    <div class="offset-lg-3 col-lg-4" style="padding-top: 35px;" *ngIf="auth.hasPermission('strankeedit')">
        <button type="button" class="btn btn-sm btn-secondary mb-3 w-100 w-lg-auto" (click)="coppyFirstDayToAll()"><fa-icon
                icon="copy"></fa-icon> Copy first day to all</button><br>
        <button type="submit" class="btn btn-primary mb-3 w-100 w-lg-auto"
            [disabled]="!form.valid && !auth.hasPermission('strankeedit')">Submit</button>
    </div>
</form>