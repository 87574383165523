import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';


import { registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import localeEn from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCoffee, fas } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from './services/auth.service';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { HttpStatusInterceptor } from './interceptors/httpstatus.interceptor';

import { LoginComponent } from './components/login/login.component';
import { MyAppComponent } from './components/app/app.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { GridModule, ExcelModule  } from '@progress/kendo-angular-grid';
import { AlarmComponent } from './components/alarm/alarm.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AlarmService } from './services/alarm.service';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { UserService } from './services/user.service';
import { AlarmWithMapComponent } from './components/alarmWithMap/alarmWithMap';
import { LocationsCenectivityComponent } from './components/locations-cenectivity/locations-cenectivity-component';
import { LocationsComponent } from './components/locations/locations.component';
import { LocationEventsComponent } from './components/location-events/location-events.component';
import { SiteManageComponent } from './components/site-manage/site-manage.component';
import { AuditLogComponent } from './components/app-user/audit-log/audit-log.component';
import { SiteDetailsComponent } from './components/site-manage/site-details/site-details.component';
import { SiteScheduleComponent } from './components/site-manage/site-schedule/site-schedule.component';
import { SiteDevicesComponent } from './components/site-manage/site-devices/site-devices.component';
import { SiteUsersComponent } from './components/site-manage/site-users/site-users.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LocationService } from './services/location.service';
import { AppNotifyService } from './services/notify.service';
import { AddSiteComponent } from './components/add-site/add-site.component';

import { MapsService } from './services/maps.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AuditLogService } from './services/auditlog.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { GridService } from './services/grid.service';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { AppUsersComponent } from './components/app-users/app-users.component';
import { AppUserComponent } from './components/app-user/app-user.component';
import { UserEditComponent } from './components/app-user/user-edit/user-edit.component';
import { DomainService } from './services/domain.service';
import { SiteNotificationsComponent } from './components/site-manage/site-notifications/site-notifications.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { SiteSettingsComponent } from './components/site-manage/site-settings/site-settings.component';
import { ScreenService } from './services/screen.service';





registerLocaleData(localeSl, 'sl-SI');
registerLocaleData(localeEn, 'en-GB');


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MyAppComponent,
    NotFoundComponent,
    AlarmComponent,
    AlarmWithMapComponent,
    LocationsCenectivityComponent,
    LocationsComponent,
    LocationEventsComponent,
    SiteManageComponent,
    AuditLogComponent,
    SiteDetailsComponent,
    SiteScheduleComponent,
    SiteDevicesComponent,
    SiteNotificationsComponent,
    SiteUsersComponent,
    AddSiteComponent,
    UnauthorizedComponent,
    AppUsersComponent,
    AppUserComponent,
    UserEditComponent,
    PasswordResetComponent,
    SiteSettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NotificationModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GridModule,
    ExcelModule,
    LayoutModule,
    ToolBarModule,
    DialogsModule,
    DateInputsModule,
    DropDownsModule ,
    GoogleMapsModule,
    InputsModule,
    TooltipModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpStatusInterceptor, multi: true },
    { 
      provide: LOCALE_ID,
      deps: [AuthService],
      useFactory: (auth:AuthService) => auth.getLanguage()
    },
    AlarmService,
    LocationService,
    MapsService,
    UserService,
    AppNotifyService,
    AuditLogService,
    GridService,
    DomainService,
    ScreenService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(faCoffee);
  }
 }
