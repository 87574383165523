<div class="h-100">
    <div class="row px-3 py-2 bg-light">
        <div class="col-lg-1 mb-1">
            <input type="radio" id="ipCom" kendoRadioButton value="ipLink" [(ngModel)]="filters.eventType"
                (ngModelChange)="onFilter()" />
            <label class="ms-2" for="ipCom">Heartbeat Status</label>
        </div>
        <div class="col-lg-1 mb-1">
            <input type="radio" id="periodicTest" kendoRadioButton value="periodicTest" [(ngModel)]="filters.eventType"
                (ngModelChange)="onFilter()" />
            <label class="ms-2" for="periodicTest">Periodic Test</label>
        </div>
        <div class="col-lg-2 mb-1">
            <input type="text" class="form-control form-control-sm"
                [(ngModel)]="filters.search" (ngModelChange)="onFilter()" placeholder="Type to filter locations...">
        </div>
        <div class="col-lg-2 mb-1" *ngIf="auth.hasPermission('su_user')">
            <kendo-dropdownlist [data]="customers" [textField]="'text'" [valueField]="'value'" class=""
                [filterable]="true" [(ngModel)]="filters.companyId" [valuePrimitive]="true" style="min-width: 200px;"
                [defaultItem]="{ text: 'Select Customer', value: null }" (valueChange)="onFilter()">
            </kendo-dropdownlist>
        </div>
        <div class="col-lg-2 mb-1">
            <kendo-dropdownlist [data]="stateFilters" [textField]="'text'" [valueField]="'value'" class=""
                [filterable]="true" [(ngModel)]="filters.statusId" [valuePrimitive]="true" style="min-width: 200px;"
                [defaultItem]="{ text: 'Filter by heartbeat status', value: null }" (valueChange)="onFilter()">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span>
                        <img [src]='dataItem.iconPath'> {{ dataItem.text }}
                    </span>
                </ng-template>
            </kendo-dropdownlist>
        </div>
        <div class="col"></div>
        <div class="col-lg-1 mb-1 align-self-end text-end" *ngIf="auth.hasPermission('su_user')">
            <button kendoButton class="btn btn-primary btn-sm w-100 w-lg-auto" (click)="exportToExcel()">
                Export All
              </button>
        </div>
    </div>

    <google-map width="100%" height="100%" [zoom]="mapZoom" [center]="mapCenter" [options]="mapOptions">
        <map-marker #marker="mapMarker" *ngFor="let m of mapMarkers" [position]="m.position" [label]="m.label"
            [title]="m.title" [options]="m.options" (mapClick)="markerClick(marker, m.info)">
        </map-marker>

        <map-info-window (closeclick)="infoWindowClosed()"
            [options]="{ headerContent: mapInfoWindowContent?.storeName }">
            <div style="min-width: 200px;">
                <div class="d-flex mb-2">
                    <div style="width: 90px;"><b>Site ID:</b></div>
                    <div>{{activeAccountNr}}</div>
                </div>
                <div class="d-flex mb-2">
                    <div style="width: 90px;"><b>Address:</b></div>
                    <div>{{mapInfoWindowContent?.storeAddress}}</div>
                </div>
                <div class="d-flex mb-2">
                    <div style="width: 90px;"><b>Phone:</b></div>
                    <div><a href="tel:{{mapInfoWindowContent?.phoneNumber}}">{{mapInfoWindowContent?.storePhone}}</a>
                    </div>
                </div>
                <div class="d-flex mb-2">
                    <div style="width: 190px;"><b>Periodic test (24 hour):</b></div>
                    <div>
                        <img [src]="mapInfoWindowContent?.testStatusIcon?.iconPath">
                        {{mapInfoWindowContent?.testStatusIcon?.text}}
                    </div>
                </div>
                <div class="d-flex mb-2">
                    <div style="width: 190px;"><b>Heartbeat test (5 minute):</b></div>
                    <div><img [src]="mapInfoWindowContent?.ipLinkStatusIcon?.iconPath">
                        {{mapInfoWindowContent?.ipLinkStatusIcon?.text}}</div>
                </div>
                <div class="fw-bolder mt-3">
                    <a [routerLink]="[ '/app/locationHistory',  activeAccountNr ]" class="btn btn-sm btn-primary"
                        routerLinkActive="active">View Events</a>
                </div>
            </div>
        </map-info-window>

    </google-map>
</div>